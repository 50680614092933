@import 'variable.less';

@media (min-width: 1100px) and (max-width: 1399px) {
  .botoomStickyBar {
    .listingAction {
      align-items: center;
      .bottomStickyButton {
        span {
          font-size: 11px;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1110px) {
  .botoomStickyBar {
    .bottomStickyButton {
      padding: 3px 3px;

      line-height: 13px;
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .diamondSearch {
    .diamondSearchWrapper {
      .diamondSearchItem {
        .diamondSearchDetail {
          .diamondSearchShape {
            li {
              width: 16.65%;
            }
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1199px) {
  .botoomStickyBar {
    padding: 10px 5px;
    // flex-wrap: wrap;
    .bottomStickyButton {
      margin: 0 2px;
      font-size: 12px;
      span {
        font-size: 10px;
      }
    }
    .select-diamonds {
      .ant-select {
        width: 100px;
      }
    }
    &.diamondListingAction {
      // bottom: -88px;
      .listingPagePagination {
        // margin-top: 10px;
        // justify-content: center;
        // width: 100%;
      }
      .listingAction {
        // justify-content: center;
        // width: 100%;
      }
    }
  }
  .diamondSearch {
    .diamondSearchWrapper {
      .diamondSearchItem {
        flex-wrap: wrap;
        .diamondSearchDetail {
          width: 100%;
          .diamondSearchShape {
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;
            width: 100%;
            li {
              width: 16.65%;
              min-width: 100px;
              span {
                word-break: break-word;
              }
            }
          }
        }
      }
    }
  }
  .shadeBlock {
    .milkyBlock,
    .eyecleanBlock {
      flex-wrap: wrap;
      .diamondSearchLabel {
        text-align: left;
        margin-right: 0;
        margin-left: 5px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .botoomStickyBar {
    padding: 10px 5px;
    // display: block !important;
  }
  .diamondSearch {
    .diamondSearchWrapper {
      .diamondSearchItem {
        flex-wrap: wrap;
        .diamondSearchDetail {
          width: 100%;
          .parameter-mh {
            .diamondSearchInnerblock {
              .diamondSearchCaratRange {
                width: calc(100% - 0px);
              }
            }
          }
          .diamondSearchInnerblock {
            .diamondSearchCaratRange {
              width: calc(100% - 30px);
            }
          }
          .diamondSearchShape {
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;
            width: 100%;
            li {
              width: 16.65%;
              min-width: 100px;
              span {
                word-break: break-word;
              }
            }
          }
          .searchSelectValue {
            flex-wrap: wrap;
            .diamondSearchInnerblock {
              width: 49%;
            }
          }
        }
        .diamondSearchLabel {
          width: 100%;
          margin-bottom: 5px;
        }
        .floBlock,
        .labBlock {
          width: 100%;
          flex-wrap: wrap;
          .diamondSearchLabel {
            width: 100% !important;
          }
          &.floBlock {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .mobileSearchBottom {
    display: none;
  }
}
@media (min-width: 650px) and (max-width: 900px) {
  .diamondSearch {
    .diamondSearchWrapper {
      .Size--ILcUL {
        flex-wrap: wrap;
        // padding: 10px 5px;
        .SizeLabel--1m5l9 {
          // padding-bottom: 10px;
        }
        .SizeFilter--3js9f {
          flex-direction: unset;
          flex-wrap: wrap;
          padding-top: 10px;
        }
      }
    }
  }
}
.Size--ILcUL {
  .SizeFilter--3js9f {
    flex-wrap: wrap !important;
  }
}
@media (max-width: 767px) {
  .diamondSearch .diamondSearchWrapper .matchpairSearch .diamondSearchItem.floBlock {
    width: 100%;
    border-left: 0px solid #ddd;
  }
  .diamondSearch .diamondSearchWrapper .matchpairSearch .diamondSearchItem.labBlock {
    width: 100%;
    border-left: 0px solid #ddd;
  }
  .diffrentBlockWrapper {
    .threeBlockPart {
      width: 100%;
      margin-top: 8px;
      border-left: none;
      padding-left: 0;
      &:first-child {
        width: 100%;
      }
      .threeBlockDetail {
        width: 100%;
        padding-left: 0;
      }
      .threeInnerBlock {
        width: 100% !important;
      }
    }
  }
  .matchpairSearch {
    padding: 10px;
  }
  .fullBlockInnerBlock {
    background-color: #fff;
  }
  .smallLabelInner {
    padding: 15px 10px 0 10px;
    display: block;
  }
  .diamondSearch .br-1 {
    align-items: flex-start !important;
    border-right: 0px solid #ddd;
  }
  .diamondSearch .plr-5 {
    padding: 0px 0px;
  }
  .diamondSearch .diamondSearchWrapper .diamondSearchFourBlock {
    border-bottom: 0px solid #ddd;
  }
  .diamondSearch .diamondSearchWrapper .matchpairSearch .diamondSearchItem.labBlock.w-33,
  .diamondSearch .diamondSearchWrapper .matchpairSearch .diamondSearchItem.diamondSearchFourBlock.w-33,
  .diamondSearch .diamondSearchWrapper .matchpairSearch .diamondSearchItem.floBlock.w-33 {
    width: 100%;
  }
  .diamondSearch .diamondSearchWrapper .matchpairSearch .diamondSearchItem .diamondSearchFourBlock.w-33,
  .diamondSearch .diamondSearchWrapper .matchpairSearch .diamondSearchItem.labBlock.w-33,
  .diamondSearch .diamondSearchWrapper .matchpairSearch .diamondSearchItem.floBlock.w-33 {
    border-right: 0px solid #ddd;
    border-left: 0px solid #ddd;
    padding: 10px;
  }
  .diamondSearch .diamondSearchWrapper .diamondSearchItem {
    margin-bottom: 0px;
  }
  .diamondSearch .diamondSearchWrapper .matchpairSearch .diamondSearchItem {
    padding: 0px;
  }
  .diamondSearch {
    .searchTopBox {
      position: relative;
      flex-wrap: wrap;
      .mainHeadingTitle {
        padding: 0 10px;
        margin-bottom: 0;
      }
      // display: block !important;
      // position: sticky;
      // top: 62px;
      // z-index: 2;
    }
    .diamondSearchWrapper {
      // background: @mobilebglight;
      background: transparent;
      // padding-bottom: 50px;
      .diamondSearchItem {
        display: block !important;
        // background-color: #fff;
        background-color: transparent;
        border-bottom: none;
        margin-bottom: 10px;
        padding: 10px;
        .haBlock,
        .brilliance {
          display: block !important;
        }
        &.makeGroup {
          .d-flex {
            margin-bottom: 5px;
            flex-wrap: wrap;
          }
          &.full-width {
            .diamondSearchFourBlock {
              .w-20pr {
                width: 100%;
                margin-bottom: 10px;
                padding: 10px;
              }
            }
          }
        }
        .diamondSearchLabel {
          width: 100%;
          margin-bottom: 5px;
        }
        .diamondSearchDetail {
          // width: 100% !important;
          width: calc(100% - 0px) !important;
          height: auto;
          .diamondSearchShape {
            display: flex;
            flex-wrap: nowrap;
            overflow: auto;
            width: 100%;
            li {
              width: 100px;
              min-width: 100px;
              margin: 0 10px 0 0;
              padding: 16px 5px;
            }
            &::-webkit-scrollbar {
              display: none;
            }
          }
          .shapeMoewLess {
            display: none;
          }
          .diamondSearchList {
            flex-wrap: nowrap;
            overflow: auto;
            height: auto;
            li {
              white-space: nowrap;
              min-width: auto;
            }
            &::-webkit-scrollbar {
              display: none;
            }
            &.whiteColor {
              width: 100%;
              overflow: auto;
              height: auto;
            }
          }
          .smallMoreLess {
            display: none;
          }
          .searchSelectValue {
            display: block !important;
            .diamondSearchInnerblock {
              width: 100%;
              margin-right: 0;
              margin-top: 15px;
            }
          }
          .diamondSearchInnerblock {
            width: 100%;
            margin-bottom: 15px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .diamondSearchCaratRange {
            width: calc(100% - 10px) !important;
            .fromToValue {
              .fromToValueInput {
                width: 48%;
              }
            }
          }
          .addCaratButton {
            margin-left: 5px;
            min-width: 95px;
            margin-top: 20px;
            padding: 5px 10px;
          }
          .m-bg-remove {
            .addCaratButton {
              margin-top: 0px;
            }
          }
        }
        &.fancyColorBlock {
          padding: 10px;
          .diamondSearchLabel {
            padding: 0;
          }
          .diamondSearchDetail {
            border-bottom: none;
            padding: 0;
            margin-left: 0;
          }
        }
        .floBlock,
        .labBlock {
          width: 100%;
          display: block !important;
          &.floBlock {
            margin-bottom: 10px;
          }
        }
        &.makeGroup {
          width: 100%;
        }
        .haBlock,
        &.floBlock,
        &.labBlock,
        .brilliance {
          width: 100%;
          &.haBlock {
            margin-bottom: 8px;
          }
          .diamondSearchLabel {
            margin-bottom: 0;
          }
        }
      }
      .Size--ILcUL {
        flex-wrap: wrap;
        padding: 10px 5px;
        .SizeFilter--3js9f {
          flex-wrap: nowrap;
          overflow: auto;
          height: auto;
          padding: 10px 0px;
        }
      }
      .fullBlockInnerBlock {
        .diamondSearchItem {
          margin-bottom: 0 !important;
        }
      }
    }
    .one_block {
      background-color: #f9fff3;
    }
    .three_block {
      background-color: #f9fff3;
    }
  }
  .diamondSearchSticky {
    display: none;
  }
  .mobileSearchBottom {
    box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
    position: fixed;
    bottom: 0;
    padding: 10px 0 8px 0px;
    width: 100%;
    display: flex;
    border-radius: 10px 10px 0 0;
    z-index: 203;
    // z-index: 10;
    background-color: #fff;
    justify-content: space-between;
    .mobileSearchActionItem {
      width: 25%;
      text-align: center;
      img {
        width: 14px;
        display: block;
        height: 14px;
        margin: auto auto 5px auto;
      }
      span {
        color: #000;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 11px;
        line-height: 14px;
        margin-top: 2px;
        display: block;
      }
    }
  }
  .MoreSearchOptionItem {
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        padding: 10px 0;
        border-bottom: 1px solid #e2e2e2;
        a {
          color: #000;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
  .diamondSearch {
    .diamondSearchWrapper {
      .diamondSearchItem {
        .diamondSearchDetail {
          .diamondSearchList {
            li {
              border: 1px solid #b3b3b3 !important;
              background-color: #fff !important;
              color: #000 !important;
              &.diamondSearchCaratRange {
                &.m-bg-remove {
                  background-color: transparent !important;
                  border: 0px solid #605f5f !important;
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 500px) {
  // .diamondSearch .diamondSearchWrapper .diamondSearchItem .diamondSearchDetail .diamondSearchList li.active {
  // .diamondSearch .diamondSearchWrapper .diamondSearchItem .diamondSearchDetail .diamondSearchList li:hover {
  //   background-color: #ffffff;
  //   color: #000;
  // }

  .diamondSearch .diamondSearchWrapper .diamondSearchItem .diamondSearchDetail .diamondSearchShape li:hover {
    background-color: #ffffff;
    color: #000;
  }
  .diamondSearch
    .diamondSearchWrapper
    .diamondSearchItem
    .diamondSearchDetail
    .diamondSearchShape
    li.false
    span
    svg
    path,
  .diamondSearch .diamondSearchWrapper .diamondSearchItem .diamondSearchDetail .diamondSearchShape li.false span,
  .diamondSearch
    .diamondSearchWrapper
    .diamondSearchItem
    .diamondSearchDetail
    .diamondSearchShape
    li.false
    .diamondSearch
    .diamondSearchWrapper
    .diamondSearchItem
    .diamondSearchDetail
    .diamondSearchShape
    li.false {
    background-color: #ffffff;
    color: #000;
    fill: #000;
    stroke: #000;
  }
  .diamondSearch .diamondSearchWrapper .diamondSearchItem .diamondSearchDetail .diamondSearchShape li.active {
    background-color: #000;
    color: #000;
    border: 1px solid #605f5f;
  }
}
