@import "../../../styles/variable.less";

.salesPersonInnerItem {
  white-space: nowrap;
}

.SalesPersonWrapper{
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding-left: 10px;
    .footerDropdownBlock{
        width: 30%;
    }
    .salesPersonProfile{
        width: 30px;
        height: 30px;
        border-radius: 100%;
        position: relative;
        img.profilePicImg{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 100%;
        }
        &:after{
            content: "";
            width: 1px;
            height: 25px;
            position: absolute;
            background-color: #515050;
            left: -10px;
            margin: auto;
            top: 0;
            bottom: 0;
        }
    }
    .salesPersonDetail{
        margin-left: 6px;
        width: calc(100% - 36px);
        display: flex;
        align-items: center;
        cursor: pointer;
        span{
            white-space: nowrap;
            color: #000;
        }
    }
}
.salesPeronInnerDetail{
    .salesPersonInnerItem{
        display: flex;
        align-items: center;
        // background-color: #f5f6fa;
        // padding: 10px;
        border-radius: 10px;
        color: #000;
        margin-bottom: 10px;
        .salesPersonIcon{
            width: 30px;
            height: 30px;
            border-radius: 10px;
            font-weight: 600;
            // background-color: #e1e1e1;
            display: flex;
            margin-right: 10px;   
            img{
                margin: auto;
                width: 20px;
                height: 20px;
            }
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
}

@primary-color: #000;