@import 'variable';

@import '~react-intl-tel-input/dist/main.css';
@import '../assets/scss/global.css';

@import 'Header';
@import 'DiamondList';
@import 'DiamondDetail';
@import 'Account';
@import 'QuickSearch';
@import 'Compare';
@import 'DiamondListingSidePopup';
@import 'OfficePopup';
@import 'savedSeach';
@import 'DiamondSearch';
@import 'mobileHeader';
@import 'MadeInBotswana';
@import 'Dashboard';
@import 'DiamondListMobile';
@import 'DiamondDetailMobile';
@import 'DiamondSearchMobile';
@import 'mobileAccount';
@import 'notificationSetting';
@import 'dna';
@import 'custom';

@import 'diamnow/_main';

@primary-color: #000;