@import './variable.less';

.backOption {
  background-color: @themeColor;
  padding: 4px 10px;
  border-radius: 15px;
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  color: #fff;

  img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
  }
}

@primary-color: #000;