.mainHeadingTitle {
  font-size: 16px;
  // font-size: 18px;
  padding: 0 15px;
  font-weight: 600;
}
.titleheading {
  letter-spacing: 0.5px;
  position: -webkit-sticky !important;
  position: sticky !important;
  left: -0px;
  /* background-color: #f8f8f8; */
  /* border: 1px solid #c6c6c6; */
  /* color: #000; */
  font-weight: 600;
  z-index: 1;
}
.popupInnerTitle {
  font-size: 16px;
  // font-size: 18px;
  font-weight: bold;
  margin: 0 0 20px 0;
  padding: 0;
  text-decoration: underline;
  line-height: 18px;
}

@primary-color: #000;