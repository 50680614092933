@primary-color: #000000;
@primary: @primary-color;
@themeColor: @primary-color;
@themeSecondColor:#E0DCD9;
@tableBackGroundColor:#f8f8f7;
@themeLightColor:#e0e0e1;
@tableBorder:#f8f8f7;
@themeDarkBoreder:#272727;
@themeGrayColor:#e4e4e4;
@mobilebglight:#efefef;
@headerSearch:#e8d9ca;
@headerStripColor:linear-gradient(to bottom, #414143 0%,#414143 100%) !important;
@thankBg:linear-gradient(to bottom,#fff 0%,#FFF9F4 100%);
@inputBorder:#d3d4d4;
@bgLightColor:#e7dacd;
@staticBg:#fafafa;
@text: #000;
// @text: #39613e;
@dashboardCard: #fafafa;

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #ddd!important;
    -webkit-border-radius: 100px;
}
::-webkit-scrollbar-thumb {
    background-color: #999 !important;
}
::selection{
    background-color: @themeColor;
}