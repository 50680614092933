@import '../../../styles/variable.less';

.countBox {
  position: absolute;
  top: -14px;
  // top: -10px;
  left: 5px;

  // top: -18px;
  // left: 10px;

  // padding: 4px;
  // text-align: center;
  // text-shadow: 0 0 4px #000;
  // line-height: 15px;
  // color: #fff;
  // color: #000000;
  // color: #5a5757;
  // font-size: 10px;
  // font-weight: 600;
  // z-index: 9000;

  min-width: 18px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #f00;
  border-radius: 10px;
}

@primary-color: #000;