@import 'variable.less';

.ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.55);
}
.stone-detail-sidebar {
  z-index: 100001;
  // width: 650px !important;
  transform: translateX(0) !important;
  .ant-drawer-content-wrapper {
    width: 750px !important;
    .ant-drawer-content {
      overflow: visible;
    }
    .ant-drawer-body {
      padding: 16px 16px 58px 16px;
    }
    .ant-drawer-header-no-title .ant-drawer-close {
      background: #fff;
      right: auto;
      left: 0;
      border-radius: 20px;
      top: 8px;
      width: 40px;
      height: 40px;
      line-height: 42px;
      font-size: 16px;
      // font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &.ant-drawer-open {
    .ant-drawer-header-no-title .ant-drawer-close {
      left: -46px;
    }
  }
  &.xl-size {
    .ant-drawer-content-wrapper {
      width: 90% !important;
    }
  }
}
.sideBarPopupButton {
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  left: 0;
  background-color: #fff;
  justify-content: center;
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
  padding: 8px 10px;
}
// .commonButton{
//     background-color: #fff;
//     margin: 3px;
//     border: 1px solid #ddd;
//     padding: 6px 10px;
//     border-radius: 5px;
//     color: #000;
//     font-weight: 500;
// }
.commonTextArea {
  label {
    color: #000;
    font-size: 14px;
    display: block;
    width: 100%;
    font-weight: 600;
  }
  textarea {
    padding: 10px;
    border: 1px solid #e4e4e4;
    width: 100%;
    height: 100px;
    &:focus {
      outline: none;
    }
  }
}
.DiamondDetailPopup {
  display: flex;
  flex-wrap: wrap;
  .DiamondDetailPopupItem {
    width: 50%;
    display: flex;
    margin-bottom: 10px;
    span {
      color: #000;
      &:first-child {
        text-transform: uppercase;
        font-size: 13px;
        margin-right: 10px;
        min-width: 140px;
        // font-weight: 600;
      }
      &:last-child {
        // font-weight: 500;
        color: #000;
        font-size: 14px;
        text-transform: capitalize;
      }
    }
  }
  .sb_address {
    width: 50%;
    .from-group {
      width: 100%;
    }
  }
}
.popupNote {
  margin: 10px 0;
  span {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #000;
  }
  p {
    color: #333;
    font-size: 14px;
    line-height: 24px;
    a {
      text-decoration: underline;
      color: #000;
    }
  }
}

.offerPopup {
  .ant-drawer-content-wrapper {
    width: 90% !important;
  }
}

@media (max-width: 767px) {
  .stone-detail-sidebarChange {
    width: 100%;
    height: 0;
    bottom: -100%;
    // .ant-drawer-close{
    //     display: none !important;
    // }
    .ant-drawer-close {
      background-color: #fff;
      width: 40px;
      height: 40px;
      position: absolute;
      top: -20px !important;
      left: 0 !important;
      right: 0 !important;
      border-radius: 100%;
      margin: auto !important;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: #000;
      }
    }
    .ant-drawer-content,
    .ant-drawer-wrapper-body {
      overflow: visible;
    }
    .ant-drawer-content-wrapper {
      width: 100% !important;
      bottom: 0;
      top: auto;
      height: auto;
      overflow: auto;
      transform: translate(0, 100%) !important;
      overflow: visible;
      .ant-drawer-body {
        max-height: calc(100vh - 400px);
        overflow: auto;
        min-height: calc(100vh - 400px);
      }
    }
    &.ant-drawer-open {
      height: 100%;
      bottom: 0;
      .ant-drawer-content-wrapper {
        transform: translateY(0) !important;
      }
    }
    .confirmListOption {
      .from-group {
        width: 100%;
      }
    }
    .DiamondDetailPopup {
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 10px;
      .DiamondDetailPopupItem {
        width: auto;
        display: block;
        border-right: none;
        margin-right: 0;
        text-align: center;
        justify-content: space-between;
        span {
          text-transform: capitalize;
          display: block;
          margin: 0;
          font-size: 12px;
        }
      }
    }
    &.xl-size {
      .ant-drawer-content-wrapper {
        width: 100% !important;
      }
    }
  }
  .stone-detail-sidebar {
    width: 100%;
    height: 0;
    bottom: -100%;
    // .ant-drawer-close{
    //     display: none !important;
    // }
    .ant-drawer-close {
      background-color: #fff;
      width: 40px;
      height: 40px;
      position: absolute;
      top: -20px !important;
      left: 0 !important;
      right: 0 !important;
      border-radius: 100%;
      margin: auto !important;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: #000;
      }
    }
    .ant-drawer-content,
    .ant-drawer-wrapper-body {
      overflow: visible;
    }
    .ant-drawer-content-wrapper {
      width: 100% !important;
      bottom: 0;
      top: auto;
      height: auto;
      overflow: auto;
      transform: translate(0, 100%) !important;
      overflow: visible;
      .ant-drawer-body {
        max-height: calc(100vh - 60px);
        overflow: auto;
        min-height: calc(100vh - 60px);
        .shareDropdownMobileHead {
          .shareStoneMobileItem {
            .bottom-border {
            }
          }
        }
      }
    }
    &.ant-drawer-open {
      height: 100%;
      bottom: 0;
      .ant-drawer-content-wrapper {
        transform: translateY(0) !important;
      }
    }
    .confirmListOption {
      .from-group {
        width: 100%;
      }
    }
    .DiamondDetailPopup {
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 10px;
      .DiamondDetailPopupItem {
        width: auto;
        display: block;
        border-right: none;
        margin-right: 0;
        text-align: center;
        justify-content: space-between;
        span {
          text-transform: capitalize;
          display: block;
          margin: 0;
          font-size: 12px;
        }
      }
    }
    &.xl-size {
      .ant-drawer-content-wrapper {
        width: 100% !important;
      }
    }
  }
  .offerTopBlock {
    display: block !important;
    margin-bottom: 0 !important;
    .popupInnerTitle {
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
  }
  .offerInputBox {
    flex-wrap: wrap;
  }
  .smallSidebarMobilePopup {
    .ant-drawer-body {
      min-height: auto !important;
    }
    &.onlySelectMobileOption {
      .ant-drawer-body {
        padding: 25px !important;
      }
    }
    .quoteSelectOption {
      margin: 0;
      li {
        list-style: none;
        width: 100%;
        .ant-radio-wrapper {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }
}
.searchPopupCommonTable {
  height: calc(100vh - 112px);
  &.halfBlockPopup {
    height: calc(100vh - 198px);
  }
  .tabInnerTableScroll {
    height: 50% !important;
  }
  .finalCalDetail {
    align-items: flex-start;
    height: 50%;
  }
  .notePopupScroll {
    height: 99% !important;
  }
}
.diamondDetailRight {
  .searchPopupCommonTable {
    &.halfBlockPopup {
      height: calc(100vh - 131px);
    }
  }
}
