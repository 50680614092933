#root {
  overflow: auto;
  max-height: 100vh;
}

.dn {
  &-list {
    &-top {
      width: 100%;
      padding-top: 8px;
    }

    &-header {
      display: flex;
      flex-direction: row;
      padding: 0 0.9rem;
      width: 100%;
    }

    &-title {
      margin-top: 0;
      margin-right: 1rem;
      margin-bottom: 0;
      margin-left: 0;
      font-size: 1.1rem;
      font-weight: 600;
    }

    &-tags {
      display: flex;
      flex-direction: row;
      margin-left: auto;

      &-title {
        margin: auto 0.5rem auto auto;
        font-size: 1rem;
        font-weight: 600;
      }

      .ant-tag {
        margin-top: auto;
        margin-bottom: auto;
        cursor: pointer;
        font-size: 0.9rem;
        font-weight: 600;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &-table {
    tr:not(.dn-group) {
      &:hover {
        > td {
          background-color: #e6e6e6 !important;
        }
      }
    }

    .cell-shadow-left {
      position: relative;

      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: auto;
        box-shadow: 2px 0px 3px #ccc;
        width: 1px;
        content: '';
      }
    }
  }

  &-sort {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-left: 0.5rem;
    gap: 0.5rem;

    &-icons {
      display: grid;
      grid-template-rows: 1fr;
      max-width: 8px;
      height: auto;
      row-gap: 1px;

      img {
        max-width: 8px;
        height: auto;
      }
    }

    &-count {
      font-size: 0.75rem;
    }

    &-clear {
      margin-right: 0.5rem;

      img {
        width: 10px;
        height: 10px;
      }
    }
  }
}
