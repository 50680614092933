@import 'variable.less';
@media screen and (max-width: 1199px) {
  .accountWrapper {
    .accountTopBlock {
      .accountUser {
        .accountTitle {
          padding-left: 10px;
        }
      }
    }
  }
}
@media (min-width: 768px) {
  .mobileAccountBlock {
    display: none;
  }
  .accountUser {
    img {
      display: none;
    }
  }
  .mobileAction {
    display: none;
  }
}

@media (max-width: 767px) {
  .profileHead {
    padding: 15px 15px 0 15px;
  }
  .accountWrapper {
    margin: 0;
    background-color: @mobilebglight;
    .accountTopBlock {
      padding: 15px;
      margin-bottom: 10px;
      background-color: #fff;
      .accountUser {
        width: 100%;
        display: flex;
        align-items: center;
        .accountTitle {
          // display: none;
        }
        .userRightDetail {
          margin-left: 10px;
        }
        .mobileUserTitle {
          font-size: 18px;
          font-weight: 600;
          line-height: 20px;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
        }
        img {
          width: 80px;
          height: 80px;
          border-radius: 100%;
        }
      }
    }
    .mobileAccountBlock {
      .mobileAccountList {
        margin-bottom: 10px;
        padding: 10px 15px;
        background-color: #fff;
        h2 {
          font-size: 18px;
          font-weight: 600;
        }
        ul {
          li {
            list-style: none;
            margin: 10px 0;
            border-bottom: 1px solid #eaeaea;
            padding-bottom: 10px;
            a {
              font-size: 14px;
              color: #212121;
              display: block;
              width: 100%;
              display: flex;
              justify-content: space-between;
              font-weight: 500;
            }
            img {
              width: 12px;
              height: 12px;
            }
            &:last-child {
              border-bottom: none;
              padding-bottom: 0;
            }
          }
        }
      }
    }
    .mobileLogOutOption {
      padding: 10px;
      color: #fff;
      position: sticky;
      bottom: 0;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      background-color: @themeColor;
    }
  }
  .accountTab {
    display: none !important;
  }

  .profleMainBlock {
    padding: 15px;
    padding-bottom: 57px;
  }
  .sideBarPopupButton {
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 1;
    .commonButton,
    .commonOutline {
      width: 50%;
      text-align: center;
      padding: 6px 10px;
      font-size: 13px;
    }
  }
  .m-p-r {
    .sideBarPopupButton {
      position: fixed;
      box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
    }
  }
  .addressList {
    .addressBlock {
      .addressItem {
        width: 100%;
      }
    }
  }
  .addressWrapper,
  .addUserPopup,
  .changePasswordBlock {
    .profileDetailSpace {
      padding: 15px 0;
    }
  }

  .mobileUserList {
    .mobileUserListItem {
      border: 1px solid #e2e2e2;
      border-radius: 10px;
      margin-bottom: 10px;
      position: relative;
      .mobileUserListTop {
        background-color: #fafafa;
        padding: 10px;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid #e2e2e2;
        justify-content: space-between;
        display: flex;
        .mobileUserCompanyName {
          font-weight: 600;
          color: #000;
        }
        .mobileUserListBottomRight {
          span {
            color: #000;
            margin-left: 5px;
          }
        }
      }
      .mobileUserListBottom {
        display: flex;
        padding: 5px 10px;
        align-items: center;
        border-radius: 0 0 10px 10px;
        justify-content: space-between;
        .mobileUserListBottomLeft {
          span {
            display: block;
            color: #000;
            font-weight: 500;
            font-size: 13px;
          }
        }
        .mobileUserListAction {
          .cardIcon {
            img {
              margin: 3px;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
  .userManageTop {
    margin-top: 0;
  }
  .manageDiamondSequence {
    .userManageTop {
      padding: 0 15px;
      .commonButton {
        display: none;
      }
    }
  }
  .profileDetailSpace {
    padding: 15px 15px 60px 15px;
  }
}
