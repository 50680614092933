@import 'variable.less';

.searchResultListWrapper {
  &.profilePage {
    margin: 0 auto;
    max-width: 100%;
  }
}

.searchResultListWrapper {
  &.offerTable {
    height: calc(100vh - 102px);
    position: relative;
  }
}

.add-sign {
  position: absolute;
  left: 2px;
  top: 2px;
  color: #868788;
}

.userDetailEdit {
  margin-left: 5px;
  margin-right: 10px;
  cursor: pointer;

  img {
    width: 16px;
    height: 16px;
  }
}

.filterMainDropdown {
  .ant-dropdown-menu {
    min-width: 320px;
    display: flex;
    flex-wrap: wrap;
    max-width: 320px;

    li {
      background-color: @tableBackGroundColor;
      padding: 2px 8px !important;
      border-radius: 20px;
      margin: 3px;
      display: flex;
      align-items: center;

      span {
        color: @themeColor;
        font-size: 11px;
      }

      .filterClose {
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background-color: @themeColor;
        display: flex;
        margin-left: 5px;

        img {
          width: 6px;
          height: 6px;
          margin: auto;
          display: block;
        }
      }
    }
  }
}

.searchResultTable table,
table.common-table {
  width: 100%;
  position: relative;

  thead {
    tr {
      th {
        text-transform: capitalize;
        color: #fff;
        font-size: 13px;
        font-weight: 600;
        padding: 5px 3px 5px 3px;
        text-align: center;
        position: sticky;
        top: 0;
        z-index: 100;
        cursor: pointer;
        background-color: #000;

        .selectAmount {
          color: #000 !important;
          font-weight: bold;
          font-size: 9px;
          height: 12px;
          display: block;
        }

        &:first-child {
          position: sticky;
          left: 0px;
          z-index: 5;
          color: #fff;
          background-color: #000;
        }
      }
    }
  }

  tbody {
    .tableSortingTd {
      position: absolute;
      left: -20px;
      margin: 10px 0;
      cursor: move;

      img {
        width: 16px;
        height: 16px;
      }
    }

    tr {
      background-color: #fff;
      position: relative;

      td {
        cursor: pointer;
        text-align: center;
        padding: 8px 8px;
        white-space: nowrap;
        border-right: 1px solid #f2f2f2;
        border-bottom: 1px solid #f2f2f2;
        vertical-align: middle;
      }

      &.selectTr {
        td {
          background: #dadada !important;
        }
      }
    }

    &.tableNewRowAdd {
      tr {
        border: 1px solid @themeColor;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

        td {
          position: relative;
          z-index: 1;
          background: #fff;

          input {
            border: none;
            text-align: center;
          }

          .SelectcolorWiseList {
            display: block;
          }
        }
      }
    }
  }
}

.common-table {
  margin-block: 1rem;
  margin-inline: 0.5rem;
}

.ant-tooltip {
  z-index: 10002;
  width: auto !important;
  max-width: fit-content;
}

.searchResultTable {
  margin: 0 2px;
  // height: calc(100vh - 210px);
  overflow: auto;

  &.shareOptionScroll {
    height: calc(100vh - 324px);
  }

  &::-webkit-scrollbar {
    width: 3px;
    height: 8px;
  }

  &.profilePagewithoutFilter {
    height: calc(100vh - 333px);
  }

  &.diamondListinSetColunm {
    padding-left: 30px;

    tr {
      td:first-child,
      th:first-child {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
      }
    }
  }

  .tableHeaderMain {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 16px;
    border-radius: 4px;
    padding: 3px 5px;
    white-space: nowrap;
    width: auto;
    font-size: 12px;
    color: #fff;
    font-weight: 600;
    letter-spacing: 0.5px;
    position: relative;

    .listIcon {
      position: absolute;
      left: -12px;
      z-index: 2;
      margin-right: 1px;
      opacity: 0;
      visibility: hidden;
      width: 17px;
      height: 17px;
      cursor: move;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .shortingRound {
      position: relative;
      z-index: 2;
      width: 8px;
      height: 14px;
      border-radius: 100%;
      background: #000;
      display: flex;
      opacity: 1;
      align-items: center;
      margin-left: 5px;
      justify-content: center;
      visibility: visible;

      // position: absolute;
      // right: -14px;
      // z-index: 2;
      // width: 14px;
      // height: 14px;
      // border-radius: 100%;
      // background: #ababab;
      // display: flex;
      // opacity: 0;
      // align-items: center;
      // margin-left: 5px;
      // justify-content: center;
      // visibility: hidden;

      .shortingRoundInner {
        height: 10px;
        display: block;

        img {
          width: 8px;
          height: 5px;
          display: block;
          margin: auto;
        }
      }
    }
    .sortRound {
      position: relative;
      z-index: 2;
      width: 8px;
      height: 14px;
      border-radius: 100%;
      background: #000;
      display: flex;
      opacity: 1;
      align-items: center;
      margin-left: 5px;
      justify-content: center;
      visibility: visible;
      .sortRoundInner {
        height: 10px;
        display: block;
        img {
          width: 8px;
          height: 5px;
          display: block;
          margin: auto;
        }
      }
    }

    .selectsortMainBlock {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      z-index: 2;

      .selectsort {
        background-color: @themeColor;
        padding: 2px 5px;
        border-radius: 13px;
        display: flex;
        line-height: 12px;
        margin-left: 6px;

        .selectsortCount {
          font-size: 9px;
          margin-right: 1px;
          color: #fff;
        }

        .selectsortArrow {
          width: 6px;
          height: 6px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .sortClose {
        width: 16px;
        height: 16px;
        background-color: #bcc0c7;
        border-radius: 100%;
        display: flex;
        opacity: 0;
        visibility: hidden;
        margin-left: 3px;
        position: absolute;
        right: -20px;

        img {
          margin: auto;
          width: 6px;
          height: 6px;
        }
      }

      .taleHeadTitle {
        position: relative;
        z-index: 0;
      }
    }
  }
}

.tabelDropdownClass {
  .menuInnerFilterOption {
    width: 200px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
    border-radius: 6px;

    .menuInnerFilterInner {
      padding: 10px;
      max-height: calc(100vh - 350px);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(186, 186, 192, 0.5) !important;
      }

      &::-webkit-scrollbar-track {
        background-color: #fff;
      }

      .llSortingTitle {
        font-size: 11px;
        text-transform: uppercase;
        color: #858585;
        font-weight: 600;
        letter-spacing: 0.5px;
      }

      .llSortingTitleList {
        .llSortingTitleItem {
          display: flex;
          position: relative;
          margin: 10px 0;
          align-items: center;
          cursor: pointer;

          .selectSortingTitle {
            background: @themeLightColor;
            border-radius: 5px;
            padding: 3px 5px;
            color: @themeColor;
            text-transform: uppercase;
            font-size: 11px;
            line-height: 14px;
          }

          .sqvanceImage {
            width: 14px;
            height: 14px;
            display: block;
            margin-right: 5px;
            cursor: move;

            img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          .sortRoundInner {
            width: 10px;
            height: 14px;
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;
            margin: auto;

            img {
              width: 10px;
              height: 8px;
              display: block;
            }
          }
        }
      }

      .llfilterMenuDropdown {
        border-top: 1px solid #e9ebf0;

        ul {
          padding: 0;
          display: flex;
          flex-wrap: wrap;

          .llSortingTitle {
            width: 100%;
            margin-bottom: 5px;
          }

          li {
            list-style: none;
            padding: 4px 10px;
            font-size: 13px;
            text-transform: uppercase;
            cursor: pointer;
            border: 1px solid #e9ebf0;
            border-radius: 4px;
            margin: 2px;
          }
        }

        .llfilterSelect {
          margin: 0 0 10px 0;
          padding: 5px 0;
          border-bottom: 1px solid #e9ebf0;

          li {
            background-color: @themeColor;
            color: #fff;
            border: 1px solid @themeColor;
            position: relative;

            .closeFilter {
              width: 12px;
              height: 12px;
              right: -4px;
              top: -6px;
              border-radius: 100%;
              background-color: @themeLightColor;
              position: absolute;
              display: flex;
              cursor: pointer;

              img {
                width: 6px;
                margin: auto;
                height: 6px;
              }
            }
          }
        }
      }
    }
  }
}

.amountCalalateOverClass {
  width: 200px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 6px;
  padding: 10px;

  .calculatorTitle {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .calculatorSelect {
    width: 100%;
  }

  button {
    border: none;
    cursor: pointer;
    background: @themeColor;
    width: 100%;
    color: #fff;
    border-radius: 6px;
    padding: 7px 10px;
    display: block;
    text-align: center;
    margin-top: 10px;
  }

  .calTotalAmount {
    border-top: 1px solid #e9ebf0;
    color: #82868d;
    font-size: 13px;
    margin: 10px -10px 0 -10px;
    padding: 8px 5px 0 5px;
    text-align: center;
  }
}

.amountCalalateOverClass.active {
  display: block !important;
}

.SelectcolorWiseList {
  width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 2px;
  background: #6bc950;

  &.red {
    background-color: #db1c1c;
    display: block;
  }

  &.blue {
    background-color: #307bea;
  }

  &.orange {
    background-color: orange;
  }

  &.yellow {
    background-color: yellow;
  }

  &.all {
    background-color: @themeColor;
  }
}

.showSatusBlock {
  display: flex;
  align-items: center;
  padding-right: 8px;
  margin-left: 6px;
  margin-right: auto;

  span {
    min-width: 60px;
    text-align: left;
  }
}

.selectActionIcon {
  display: flex;
  align-items: center;

  .selectActionIconWrapper {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: @themeColor;
        border-color: @themeColor;
      }
    }

    .ant-checkbox-inner {
      width: 16px;
      height: 16px;

      &:after {
        width: 5px;
        height: 8px;
      }
    }
  }
}

table {
  &.match-pair-z-index {
    .tableGroupingTr {
      .tableGropupingBox {
        z-index: 9 !important;
      }
    }
  }
}

.openEnquiry {
  color: #000;
  font-weight: 500;
  background: #ecfded;
  color: #429945;
  padding: 5px 13px;
  border-radius: 8px;
  font-size: 12px;
  text-transform: uppercase;
}
.closeEnquiry {
  color: #000;
  font-weight: 500;
  background: #fdecf1;
  color: rgba(245, 34, 45, 0.8313725490196079);
  padding: 5px 13px;
  border-radius: 8px;
  font-size: 12px;
  text-transform: uppercase;
}

.tableGropupingBox {
  position: absolute;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: 12px;

  .tableGroupTitle {
    display: inline-flex;
    position: sticky;
    white-space: nowrap;
    bottom: 0;
    left: -10px;
    z-index: 10;
    width: 100%;

    .tableGroupTitleInner {
      color: #fff;
      padding: 1px 5px;
      line-height: 22px;
      border-radius: 4px 4px 0 0;
      white-space: nowrap;
      background: @themeColor;
      margin-right: 5px;
      display: flex;
      align-items: center;

      span {
        font-size: 10px;
      }

      .moreOption {
        width: 10px;
        height: 10px;
        display: block;
        cursor: pointer;
        margin-left: 10px;
        display: none;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
      &:hover,
      &.moreOptionShow {
        .moreOption {
          display: block;
        }
      }

      .checkBoxSelect {
        display: block;
        margin-right: 10px;
        position: relative;
        cursor: pointer;

        img {
          width: 12px;
          height: 14px;
        }

        &:after {
          content: '';
          width: 1px;
          height: 22px;
          background: @themeDarkBoreder;
          position: absolute;
          right: -6px;
          top: -4px;
        }
      }
    }
  }
}

.tableGroupingTr {
  border: none !important;
  box-shadow: none !important;
  background: transparent !important;

  td {
    background: transparent !important;
  }
}

.groupingTableAmount {
  text-align: center !important;
  padding: 6px 8px 8px 8px !important;
  color: #000;
  font-weight: bold;
  font-size: 9px;
  height: 12px;
  cursor: pointer;
  border: none !important;

  &:first-child {
    text-align: right;
    padding: 6px 15px 8px 8px;
  }

  .selectAmount {
    position: relative;
    z-index: 1;
  }
}

.tableInput {
  width: 80px;
  margin: auto;
  position: relative;
  display: block;

  &.noteAdd {
    width: 120px;
  }

  input {
    width: 100%;
    padding: 2px 5px;
    border: 1px solid #e2e2e2;
    border-radius: 5px;
    font-size: 11px;
    color: #000;
  }

  &.tableTopOfferInput {
    input {
      font-size: 12px;
      position: absolute;
      top: -16px;
      left: 0;
      padding: 4px 5px;
    }
  }
}

.tableLink {
  color: #000;
}

.rapPrice {
  color: #db1c1c;
}

.askingPrice {
  color: #469c1a;
}

.m2mPrice {
  color: #296cb1;
}

.certificateNo {
  color: @themeColor;
  text-decoration: underline;

  &:hover {
    color: @themeColor;
    text-decoration: underline;
  }
}

.diamondDetailSummary {
  .tabInnerTableScroll {
    height: calc(100vh - 174px);
  }
}

.colorDropdown {
  background: #fff;
  box-shadow: 0 0 14px 0px rgba(0, 0, 0, 10%);
  padding: 5px 10px;
  border-radius: 5px;

  .SelectcolorWiseList {
    display: block;
    width: 12px;
    height: 12px;
    cursor: pointer;
    margin: 5px 0;
  }
}

.addNewColumButton {
  color: rgba(52, 52, 52, 0.5);
  white-space: nowrap;
  padding: 5px 8px;
  border-radius: 5px;
  margin-top: 5px;
  display: flex;
  font-size: 11px;
  align-items: center;
  cursor: pointer;
  position: absolute;

  &:hover {
    background: #e2e7ed;
  }

  img {
    width: 10px;
    margin-right: 5px;
    height: 10px;
  }
}

.filedLeftBlock {
  position: relative;
}

.closeRow {
  position: absolute;
  left: 0;

  img {
    width: 10px;
    margin-left: 5px;
    height: 10px;
  }
}

.colunmAlign {
  display: flex;
  justify-content: center;

  .colunmAlignItem {
    width: 30px;

    img {
      width: 16px;
      height: 16px;
    }
  }
}

.diamondListingAction {
  justify-content: space-between;
}

.listingPagePagination {
  display: flex;
  align-items: center;

  .clientPagination {
    display: flex;
    align-items: center;

    ul {
      margin-bottom: 0;

      li {
        line-height: 20px;

        &.active {
          background-color: @themeColor;
          color: #fff;
        }
      }
    }

    .paginationText {
      color: #7a7a7a;
      font-size: 11px;
      margin-left: 5px;
    }

    .paginationSelect {
      .ant-select-selection {
        background: #fff;
        border-radius: 5px;
        border: 1px solid #ddd;
        height: auto;

        .ant-select-selection__rendered {
          line-height: 26px;

          .ant-select-selection-selected-value {
            font-size: 11px;
          }
        }

        .ant-select-arrow {
          svg {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
  }
}

.normalTableDropdown {
  .tableDropdown {
    position: absolute;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
    background: #fff;
    border-radius: 6px;
    padding: 6px 20px;
    display: none;

    span {
      display: block;
      text-transform: uppercase;
      font-size: 13px;
      margin: 0 2px;
      border-bottom: none !important;
    }

    .tool {
      padding: 5px;
    }
  }

  &:hover {
    .tableDropdown {
      display: flex;
    }
  }
}

.numberValue {
  text-align: right;
}

.redColor {
  color: #000;
  font-weight: bold;
  font-size: 14px;
}

.greenColor {
  color: #6bc950;
}

.selectStonHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;

  h2 {
    margin: 0;
  }
}

.searchInnerResult {
  // background-color: #f3f3f3;
}

.selectStoneValueBlock {
  background-color: #f8f8f8;
  display: flex;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 9px 10px;
  // padding: 10px 10px;
  z-index: 1;
  top: 0;

  &.diamondListStone {
    width: calc(100% - 65px);
    // width: calc(100% - 30px);
  }

  .selectStoneValueItem {
    display: flex;
    // flex-wrap: wrap;
    margin-right: 15px;
    align-items: center;

    span {
      &.selectStoneLabel {
        color: rgba(52, 52, 52, 0.5);
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        margin-right: 3px;
        display: block;
      }

      &.selectStopnValue {
        color: #307bea;
        font-size: 13px;
      }
    }
  }
  .searchStoneClose {
    color: #fff;
    position: absolute;
    right: 15px;
    cursor: pointer;
    background: @themeColor;
    padding: 3px 8px;
    border-radius: 5px;
    text-transform: capitalize;
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 16px;
    display: block;
    cursor: pointer;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 24px;

    img {
      width: 10px;
      height: 10px;
    }
  }

  &.profileStonSelect {
    position: absolute;
    top: -42px;
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px solid #000;
    width: 100%;
    padding-right: 80px;
    flex-wrap: wrap;
  }
}

.FilterOption {
  margin-right: 10px;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.offerPrice {
  &.client {
    color: #ff0000;
  }

  &.admin {
    color: #469c1a;
  }

  &Hint {
    display: flex;
    flex-direction: row;
    margin: auto 0 auto 0;

    &Square {
      display: inline-block;
      width: 14px;
      height: 14px;
      margin: auto 0.5rem auto 1rem;
      border-radius: 7px;

      &.client {
        background: #ff0000;
      }

      &.admin {
        background: #469c1a;
      }
    }
  }
}

.main-terms {
  ol {
    &.ml-3 {
      margin-left: 16px;
      margin-bottom: 6px;
      li {
        color: #000;
        margin-bottom: 6px;
      }
    }
  }
  ul {
    &.a {
      list-style-type: circle;
    }
  }
  ul {
    &.b {
      list-style-type: square;
    }
  }
  ol {
    &.c {
      list-style-type: upper-roman;
    }
  }
  ol {
    &.d {
      list-style-type: lower-alpha;
    }
  }
}

.popupInnerTitle {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px 0;
  text-decoration: underline;
  line-height: 18px;
}

.confirmListOption {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;

  .from-group {
    width: 50%;
    padding-right: 10px;
  }
}

.summaryListWrapper {
  margin-bottom: 20px;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 5px;

  .smallTableScroll {
    overflow: auto;
    padding-bottom: 15px;

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }
  }

  .summaryListTop {
    th {
      color: #343434;
      font-size: 9px;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
      white-space: nowrap;
    }
  }

  .summaryListScroll {
    td {
      font-size: 11px;
      text-align: center;
      padding: 2px 5px;
      border-right: 1px solid #f2f2f2;

      &:last-child {
        border-right: none;
      }
    }
  }
}

.checkImageBox {
  img {
    width: 16px;
    height: 16px;

    &.checkClose {
      width: 12px;
      height: 12px;
    }
  }
}

.botoomStickyBar {
  box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
  background: #fff;
  position: fixed;
  bottom: 0;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0;
  z-index: 1;
  margin-left: 80px;
  width: calc(100% - 80px);
}
.botoomStickyBar .bottomStickyButton {
  border: 1px solid @themeColor;
  border-radius: 5px;
  margin: 0 3px;
  background-color: transparent;
  padding: 4px 8px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  background-color: @themeColor;
}

.botoomStickyBar .bottomStickyButton:focus {
  outline: none;
}

.botoomStickyBar .bottomStickyButton span {
  color: #fff;
  font-weight: 500;
  font-size: 12px;
  transition: all 0.5s ease-in-out;
}

.botoomStickyBar .bottomStickyButton:hover {
  background-color: #fff;
}

.botoomStickyBar .bottomStickyButton:hover span {
  color: @themeColor;
}

.clientPagination ul {
  display: flex;
  padding: 0;
  align-items: center;
}

.clientPagination li {
  list-style: none;
  width: 20px;
  height: 20px;
  font-size: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.clientPagination li img {
  width: 10px;
  height: 10px;
}

.clientPagination li.active {
  background-color: #fff;
  border-radius: 100%;
}

//   filterTop

.filterTopMainBlock {
  border-bottom: 1px dashed #e1e1e1;
  min-height: 3rem;
  padding: 5px 0;
}

.searchInnerFilterMain {
  display: flex;
  align-items: center;
  padding: 0 10px;

  .searchInnerFilterItem {
    display: flex;
    align-items: center;
    border-radius: 20px;
    margin-right: 10px;
    background-color: #f4f4f4;
    cursor: pointer;

    .searchInnerFilterTitle {
      color: #000;
      font-size: 13px;
      font-weight: 600;
      padding: 4px 0 4px 10px;
    }

    .searchMainFilterIcon {
      margin-left: 5px;

      img {
        width: 12px;
        height: 12px;
        display: block;
      }

      &.filterClose {
        padding: 5px 10px 5px 8px;
        border-left: 1px solid @themeDarkBoreder;

        img {
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.clientDetailDiamonList {
  margin-right: 10px;

  .listdetailBox {
    margin-left: 10px;
    position: relative;

    &:after {
      content: '';
      width: 1px;
      height: 10px;
      background-color: #a69e9e;
      position: absolute;
      right: -5px;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }
  }
}

.filterTopOption {
  display: flex;
  padding: 0 10px;
  align-items: center;

  &.ant-drawer-open {
    .ant-drawer-close {
      left: -65px !important;
    }
  }

  .ant-drawer-close {
    top: 10px;
    left: 0;
    right: auto;
    width: 36px;
    height: 36px;
    padding: 11px;
    background: #fff;
    border: none;
    outline: 0;
    border-radius: 50%;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.07);
    stroke: #979797;
    display: flex;
    cursor: pointer;
  }

  .filterSidebarItem {
    display: flex;
    align-items: center;
    margin-right: 10px;
    position: relative;
    border-radius: 25px;
    padding: 0 10px;
    min-height: 30px;

    .ant-calendar-picker {
      .ant-input {
        background-color: transparent;
        padding: 0 !important;
        height: auto;
        border: none;
        width: 230px;
        display: flex;
        align-items: center;

        .ant-calendar-range-picker-separator {
          color: #000;
        }

        .anticon {
          display: block;
          line-height: 30px;
          padding-left: 7px;
          height: 30px;
          top: 0;
          margin: 0;
          position: relative;
          background: transparent;

          &.anticon-close-circle {
            position: absolute;
          }

          &:after {
            content: '';
            width: 1px;
            height: 28px;
            background: @themeDarkBoreder;
            position: absolute;
            left: 0;
            display: block;
            top: 0;
            bottom: 0;
            margin: auto;
          }

          svg {
            fill: #000;
          }
        }

        .ant-calendar-range-picker-input {
          width: 45%;
          font-size: 11px;
          color: #000 !important;
        }
      }
    }

    .ant-select {
      height: 20px;
      width: 320px !important;

      .ant-select-selection {
        height: 20px;
        background: transparent;
        min-height: auto;
        padding: 0 3px;
        border-radius: 0 !important;
        border: none !important;
        box-shadow: none !important;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:active {
          box-shadow: none;
        }

        .ant-select-selection__rendered {
          line-height: normal;
          margin: 0;
          height: 20px;
          display: flex;

          .ant-select-selection-selected-value {
            color: #000 !important;
            padding-right: 6px;
            line-height: 18px;
          }

          .ant-select-selection__placeholder {
            position: relative;
            top: 0;
            margin-top: 0;
            height: auto;
          }

          ul {
            li {
              height: 20px;
              margin-top: 0;
              line-height: 20px;

              &.ant-select-selection__choice {
                margin: 0 3px;
                background: rgba(47, 109, 177, 9%);
                color: #000;
                font-size: 11px;
                height: auto;
                line-height: 18px;
                align-items: center;
                display: flex;
              }
            }
          }
        }

        .ant-select-arrow {
          position: relative;
          top: 0;
          padding-left: 5px;
          right: 0;
          margin: 0 0;

          svg {
            fill: #000;
          }
        }
      }

      &.updateStausBlock {
        min-width: 80px !important;
        width: auto !important;
        border: none;

        &:hover {
          box-shadow: none;
        }

        &.md-size {
          width: 185px !important;
        }

        &.sm-size {
          width: 80px !important;
        }
      }
    }
  }

  .filterSidebarTitle {
    font-size: 11px;
    font-weight: 600;
    color: #000 !important;
    margin: 0;
    line-height: 14px;
    white-space: nowrap;
  }
}

// offer

.offerTopCollpaseOption {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .collpaseItem {
    display: flex;
    margin: 0 5px;

    .collpaseItemBox {
      transition: background-color 0.2s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
      display: flex;
      align-items: center;
      position: relative;
      padding: 5px 7px;
      color: #7c828d;
      cursor: pointer;
      font-size: 11px;
      border: 1px solid #d8dce2;

      &:first-child {
        border-radius: 3px 0 0 3px;
      }

      &:last-child {
        border-radius: 0 3px 3px 0;
        border-left: none;
      }

      &.active {
        color: @themeColor;
      }
    }
  }
}

.offerDiffrent {
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .offerDiffrentItem {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    span {
      &:first-child {
        white-space: nowrap;
        font-size: 11px;
        color: #212121;
        margin-right: 5px;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 0.5px;
      }

      &:last-child {
        white-space: nowrap;
        color: #000;
        font-size: 13px;
        font-weight: 600;
      }
    }
  }
}

.offerStickyFiled {
  table {
    tr {
      td,
      th {
        &:last-child {
          position: sticky;
          right: 0;
          background-color: #fff;
        }

        &:nth-last-child(2) {
          position: sticky;
          right: 97px;
          background-color: #fff;
        }
      }
    }
  }
}

.totalDisscount {
  margin-right: 0 !important;
  margin-top: 20px !important;

  input {
    height: 30px;
  }
}

.offerWrapper {
  .DiamondDetailPopup {
    width: auto;
    display: flex;

    .DiamondDetailPopupItem {
      width: auto;
      justify-content: space-between;
      margin-right: 10px;
      margin-bottom: 0;
      border-right: 1px solid #ddd;
      padding-right: 10px;

      span {
        min-width: min-content !important;

        &:last-child {
          font-weight: 600;
        }
      }

      &:last-child {
        border-right: none;
        padding-right: 0;
      }
    }
  }

  .popupInnerTitle {
    margin-bottom: 0;

    h2 {
      margin-bottom: 0;
    }
  }

  .offerTopBlock {
    margin-bottom: 15px;
  }

  .offerInputBox {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .inputBlock {
      padding-top: 10px;
      margin-bottom: 0 !important;
      padding-right: 20px;
      width: 100%;
    }
  }

  .offerBottomBox {
    margin-top: 10px;
  }
}

.offerNote {
  width: 100%;
  color: #000000;
}

// grid View

.stone-grid-view {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;

  .saperator {
    position: relative;
    display: flex;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateY(50%);
      z-index: -1;
      margin: auto;
      background-color: #414143;
      height: 3px;
      content: '';
    }

    .label {
      z-index: 3;
      margin: auto;
      padding: 0.3rem;
      border: none !important;
      background-color: #fff;
      color: #414143;
      font-size: 0.8rem;
      font-weight: 600;
    }
  }

  .stone-grid-item {
    width: calc(30% - 10px);
    margin: 5px;
    border: 1px solid #e2e2e2;
    border-radius: 10px;

    .stone-grid-image {
      width: 100%;
      height: 250px;
      background: #9c9d9b;
      border-radius: 10px 10px 0 0;
      position: relative;
      display: flex;
      justify-content: space-between;

      .stone-grid-checkbox {
        position: absolute;
        left: 10px;
        top: 10px;
      }
      &.dubble-image {
        img {
          width: 49%;
          // width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 10px 10px 0 0;
          margin: auto;
        }
      }
      img {
        // width: 49%;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 10px 10px 0 0;
        margin: auto;
      }

      .combain-img {
        width: 100%;
      }
    }

    .stone-grid-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-top: 1px solid #e2e2e2;
      padding: 10px;
      padding-top: 10px;

      a {
        .IGI-grid {
          font-size: 13px;
          font-weight: bold;
          color: #000 !important;
          margin-right: 4px;
          text-decoration: underline;
        }
      }

      h2 {
        font-size: 13px;
        font-weight: 800;
        margin-bottom: 5px;
        line-height: 20px;
        display: flex;
      }

      .link {
        margin-bottom: 5px;
        color: #000;
        display: block;
        font-weight: bold;
        font-size: 13px;
        text-decoration: underline;
      }

      p {
        margin: 0;
        font-size: 11px;
        color: #000;
        font-weight: 500;
      }

      span {
        display: content;
        color: #000 !important;
        font-weight: 500 !important;
        font-size: 13px;
      }

      .hr-matchpair-top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        /* border-top: 1px solid #e2e2e2; */
        /* padding: 10px; */
        /* padding-top: 10px; */
        width: 100%;
        border-top: 2px solid #e4e4e4;
        margin-top: 10px;
      }

      .status-container {
        p {
          color: #000 !important;
          font-weight: 500 !important;
        }

        span.dna {
          color: #008cba !important;
        }
      }

      span.statusShow {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 11px;

        .statusIcon {
          display: block;
          margin-left: 5px;
          margin-right: 5px;
          border-radius: 100%;
          background: #6bc950;
          width: 8px;
          height: 8px;

          &.red {
            background-color: #db1c1c;
          }

          &.blue {
            background-color: #307bea;
          }
        }
      }

      .gridLeftContent {
        text-align: left;
      }

      .gridRightContent {
        text-align: right;
      }

      .layoutGridContent {
        display: flex;
        flex-direction: row;
        text-align: left;
        justify-content: space-between;

        .p_title {
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.layout-grid-view {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;

  .saperator {
    position: relative;
    display: flex;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateY(50%);
      z-index: -1;
      margin: auto;
      background-color: #414143;
      height: 3px;
      content: '';
    }

    .label {
      z-index: 3;
      margin: auto;
      padding: 0.3rem;
      border: none !important;
      background-color: #fff;
      color: #414143;
      font-size: 0.8rem;
      font-weight: 600;
    }
  }

  .stone-grid-item {
    display: flex;
    margin: 5px;
    border: 2px solid #bbbbbb;
    border-radius: 10px;
    width: calc(50% - 10px);

    .stone-grid-image {
      display: flex;
      position: relative;
      border-radius: 10px 0 0px 10px;
      background: #fafafa;
      width: 40%;
      height: 100%;
      min-height: 220px;

      .stone-grid-checkbox {
        position: absolute;
        left: 10px;
        top: 10px;
      }

      img {
        margin: auto;
        width: 100%;
        height: 100%;
        max-height: 220px;
        object-fit: contain;
        border-radius: 10px 10px 0 0;
        border-radius: 10px 0 0px 10px;
      }
    }

    .stone-grid-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-top: 1px solid #e2e2e2;
      border-top: 0;
      padding: 10px;
      padding-top: 10px;
      width: 60%;

      h2 {
        font-size: 15px;
        font-weight: 800;
        margin-bottom: 5px;
        line-height: 20px;
        display: flex;
      }

      .link {
        margin-bottom: 5px;
        color: #000;
        display: block;
        font-weight: 500;
        text-decoration: underline;
      }

      p {
        margin: 0;
        font-size: 13px;
        color: #000;
        font-weight: 500;
      }

      span {
        display: block;
        color: #000;
        font-weight: bold;
        font-size: 13px;
      }

      .status-container {
        a {
          display: flex;
          justify-content: flex-end;

          .IGI-grid {
            font-size: 14px;
            font-weight: bold;
            color: #000 !important;
            margin-right: 4px;
            text-decoration: underline;
          }
        }

        p {
          color: #000 !important;
          font-weight: 500 !important;
        }

        .flex-end {
          display: flex;
          justify-content: flex-end;
        }
      }

      span.statusShow {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 11px;

        .statusIcon {
          display: block;
          margin-left: 5px;
          border-radius: 100%;
          background: #6bc950;
          width: 8px;
          height: 8px;

          &.red {
            background-color: #db1c1c;
          }

          &.blue {
            background-color: #307bea;
          }
        }
      }

      .gridLeftContent {
        text-align: left;
      }

      .gridRightContent {
        text-align: right;
      }

      .layoutGridContent {
        display: flex;
        flex-direction: row;
        text-align: left;
        justify-content: space-between;

        .p_title {
          margin-right: 0.5rem;
        }
      }

      .gridLeftContent {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-left: 10px;

        p {
          margin: 0;

          .p_val {
            font-weight: 500;
          }
        }
      }
    }
  }
}

.gridListIcon {
  margin-right: 10px;
  cursor: pointer;

  img {
    width: 18px;
    height: 18px;
  }
}

.dropdown {
  position: relative;
}

.diamondListBottomDropdown {
  background-color: #fff;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-width: 150px;
  display: none;
  overflow: visible;
  transform: translate(0, 0) !important;
  top: auto !important;
  bottom: 0 !important;
  margin-bottom: 35px;

  &.leptopSize {
    right: -200px;
    left: auto !important;
    margin: auto;
  }

  &.show {
    display: block;
  }

  ul {
    margin: 0;

    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;

      .dropdownBottomItem {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 0;
        background-color: transparent;
        border: none;

        .dropdownIcon {
          width: 25px;
          height: 25px;
          background-color: #e4e6eb;
          border-radius: 100%;
          margin-right: 5px;
          display: flex;

          img {
            width: 14px;
            height: 14px;
            margin: auto;
          }
        }

        span {
          font-size: 13px;
          font-weight: 500;
          color: #000;
        }
      }

      .dropdownrightArrow {
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .dropdownInnerTitle {
    display: flex;
    align-items: center;
    padding: 0 10px 5px 10px;
    position: relative;
    cursor: pointer;

    img {
      width: 12px;
      height: 12px;
      margin-right: 5px;

      &.sendIcon {
        position: absolute;
        right: 5px;
        width: 16px;
        height: 16px;
      }
    }

    h2 {
      font-size: 13px;
      margin: 0;
      font-weight: 600;
      color: #000;
    }
  }
}

.dropdownIconCheckBox {
  margin-right: 5px;
}

.shareOptionSet {
  margin-top: 20px;
  max-height: 332px;
  overflow: auto;

  .shareOptionSetItem {
    width: 100%;
    text-align: center;

    .singleOnly {
      display: inline-block;
      margin: 10px;

      li {
        margin-left: 0;
        background: @staticBg;
        border: 1px solid #e1e1e1;
        background-color: @staticBg;
        border-radius: 5px;

        &.active {
          background-color: @themeLightColor;
          border: 1px solid @themeColor;
          color: @themeColor;
        }

        .shareOptionLabel {
          font-weight: 500;
          color: @themeDarkBoreder;
        }

        .dropdownBottomItem {
          display: flex;
          margin: 10px;
        }
      }

      .ant-checkbox-checked {
        &:after {
          border: 0;
        }
      }
    }

    .dropdownMainTitle {
      width: 120px;
      display: flex;

      span {
        font-weight: 600;
        font-size: 13px;
        color: #000;
      }
    }

    .shareOptionTitle {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin-bottom: 10px;
      display: block;
    }

    .excelShare {
      border-left: 1px solid #e2e2e2;
      padding-left: 10px;
    }

    ul {
      display: flex;
      width: 100%;
      justify-content: flex-start !important;
      align-items: center;
      border-bottom: 1px solid #e2e2e2;
      padding-bottom: 10px !important;
      margin-bottom: 10px !important;

      .shareDropdownUi {
        margin-left: 25px !important;
      }

      li {
        list-style: none;
        margin: 4px;
        border: 1px solid #e1e1e1;
        background-color: #fafafa;
        padding: 8px 10px;
        min-width: 100px;
        margin-right: 10px;
        cursor: pointer;
        border-radius: 5px;
        font-size: 13px;

        .dropdownBottomItem {
          display: flex;

          span {
            // &.shareOptionLabel {
            font-weight: 500;
            color: #000;
            // }
          }
          .shareOptionLabel {
            font-weight: 500;
            color: #000;
          }
        }

        &.active {
          background-color: @themeLightColor;
          border: 1px solid @themeColor;
          color: @themeColor;
        }
      }

      .dropdownRightCheck {
        display: flex;
        width: calc(100% - 120px);
        flex-wrap: wrap;
      }
    }
  }
}

.listingTopBlock {
  border-bottom: 1px dashed #e1e1e1;
  position: relative;

  .filterTopMainBlock {
    border-bottom: none;
  }
}

.infoPopover {
  width: 320px;

  .ant-popover-content {
    border-radius: 10px;
    position: relative;

    p {
      b {
        margin-right: 10px;
      }
    }

    .ant-popover-inner {
      border-radius: 10px;
    }
  }
}

.listingSelected {
  position: absolute;
  right: 0;
  background: #fff;
  padding: 10px;
  width: calc(100% - 280px) !important;
}

.demandPopupTwoValue {
  display: flex;
  justify-content: space-between;

  .inputBlock {
    width: 49%;
  }
}

.countDownBlock {
  display: flex;
  justify-content: space-between;

  .countdownItem {
    span {
      display: block;

      &:first-child {
        font-size: 13px;
        color: #000;
        font-weight: 600;
      }

      &:last-child {
        text-transform: uppercase;
        color: #555;
        font-size: 9px;
        font-weight: 500;
      }
    }
  }
}

// bid

.bidEndBlock {
  .bidEndLabel {
    font-weight: 600;
    color: #000;
  }

  .countDownBlock {
    .countdownItem {
      background-color: @themeLightColor;
      border: 1px solid @themeColor;
      border-radius: 4px;
      padding: 2px 7px;
      margin: 0 5px 5px;

      span {
        color: @themeColor;
        text-align: center;
      }
    }
  }
}

.empty-data {
  text-align: center;
  width: 98%;
  margin: 0 auto;
  height: 88%;
}

.empty-data h3 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.priceChange {
  vertical-align: bottom;

  span {
    font-size: 9px;
    margin-left: 2px;
  }
}

.bottomNote {
  text-align: center;

  span {
    display: block;
    color: #232323;
    font-size: 12px;
    line-height: 20px;

    em {
      font-style: normal;
      font-weight: 600;
      padding: 2px 5px;
      border-radius: 5px;
      background: @themeColor;
      color: #fff;
    }
  }
}

.columnSettingPage {
  table {
    tr {
      td,
      th {
        &:first-child {
          left: -30px !important;
        }
      }
    }
  }
}

.confirmStonePopUp .searchResultTable {
  height: calc(100vh - 390px);
}

.ant-notification {
  z-index: 10000001;
}

.searchResultTable.watchpopup {
  height: calc(100vh - 165px);
}

.mt-10 {
  margin-top: 10px;
}

.finalFMSelect.ant-select {
  width: 200px;
}

.tableSmallImage {
  height: 50px;
  width: 50px;

  img {
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.diamondlistInfo {
  margin-left: 5px;

  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}

.title-row {
  > td {
    margin: 3px 0 3px 0;
    position: relative;
    padding: 0 !important;
    border: none !important;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 3px;
      transform: translateY(-50%);
      background-color: #414143;
      z-index: 2;
    }
  }

  .label {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-50%);
    border: none !important;
    padding: 0px 7px;
    font-size: 12px;
    font-weight: 600;
    z-index: 3;

    div.text {
      position: relative;
      z-index: 5;
    }

    &::after {
      content: '';
      position: absolute;
      top: 8px;
      right: 0;
      left: 0;
      height: 6px;
      background-color: #ffffff;
      z-index: 4;
    }
  }

  .position-static {
    position: static !important;
  }
}

.max-w-90 {
  max-width: 89%;
}

.layout_search_panel {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e1e1e1;
  position: sticky;
  top: 0;

  label {
    font-weight: 600;
    color: #000;
    font-size: 13px;
    margin-right: 10px;
    padding-top: 7px;
    min-width: 100px;

    &.pt-0 {
      padding-top: 0;
    }
  }

  .two_block {
    display: flex;
    align-items: flex-start;
    padding: 0 15px 10px;
    width: 50%;

    .ant-select {
      width: 100%;
      &:hover,
      &:focus {
        box-shadow: none;
      }
    }

    .ant-select-selection {
      border: 0;
    }

    .ant-select-open .ant-select-selection {
      box-shadow: none;
    }
  }

  .one_block {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px 10px;

    label {
      padding-top: 0;
    }

    .diamondSearchList {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 0;
      width: 100%;

      li {
        text-transform: uppercase;
        list-style: none;
        padding: 5px 10px;
        border: 1px solid #b3b3b3;
        background-color: #fff;
        margin: 2px;
        color: #000;
        font-weight: 500;
        min-width: 50px;
        cursor: pointer;
        text-align: center;
        border-radius: 4px;
        line-height: 20px;
        &.active,
        &:hover {
          background-color: #414143;
          color: #fff;
          border: 1px solid #414143;
        }
      }
    }
  }

  .three_block {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 15px 10px;

    .mesurement {
      display: flex;
      align-items: center;
      width: 33%;
      border-right: 1px solid #ccc;
      padding-left: 15px;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-child {
        border-right: 0;
      }

      label {
        padding-top: 0;
      }

      .one_block {
        width: 40%;

        .inputBlock {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .max-w-90 {
    max-width: 88%;
  }
}

.search-result-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.stone-grid-view {
  display: flex;
  margin: 0 auto auto auto;
  width: 100%;
  max-width: 3750px;

  .stone-grid-item {
    min-width: 300px;
    max-width: 364px;
    width: 100%;
  }

  &.single-stone-grid-view,
  &.match-pair-grid-view {
    width: 100%;

    .stone-grid-item {
      min-width: unset;
      max-width: unset;
      width: 100%;
    }
  }
}

// single-stone-grid-view

@media only screen and (min-width: 3751px) {
  .stone-grid-view.single-stone-grid-view {
    max-width: 4096px;

    .stone-grid-item {
      width: calc(10% - 10px) !important;
    }
  }
}

@media only screen and (max-width: 3750px) {
  .stone-grid-view.single-stone-grid-view .stone-grid-item {
    width: calc(12.5% - 10px);
  }
}

@media only screen and (max-width: 2254px) {
  .stone-grid-view.single-stone-grid-view .stone-grid-item {
    width: calc(16.66% - 10px);
  }
}

@media only screen and (max-width: 1880px) {
  .stone-grid-view.single-stone-grid-view .stone-grid-item {
    width: calc(20% - 10px);
  }
}

@media only screen and (max-width: 1506px) {
  .stone-grid-view.single-stone-grid-view .stone-grid-item {
    width: calc(25% - 10px);
  }
}

@media only screen and (max-width: 1132px) {
  .stone-grid-view.single-stone-grid-view .stone-grid-item {
    width: calc(33.33% - 10px);
  }
}

@media only screen and (max-width: 920px) {
  .stone-grid-view.single-stone-grid-view .stone-grid-item {
    width: calc(50% - 10px);
  }
}

@media only screen and (max-width: 580px) {
  .stone-grid-view.single-stone-grid-view .stone-grid-item {
    width: 100%;
  }
}

// match-pair-grid-view

@media only screen and (min-width: 3751px) {
  .stone-grid-view.match-pair-grid-view {
    max-width: 4096px;

    .stone-grid-item {
      width: calc(12.5% - 10px) !important;
    }
  }
}

@media only screen and (max-width: 3750px) {
  .stone-grid-view.match-pair-grid-view .stone-grid-item {
    width: calc(12.5% - 10px);
  }
}

@media only screen and (max-width: 2800px) {
  .stone-grid-view.match-pair-grid-view .stone-grid-item {
    width: calc(16.66% - 10px);
  }
}

@media only screen and (max-width: 2200px) {
  .stone-grid-view.match-pair-grid-view .stone-grid-item {
    width: calc(20% - 10px);
  }
}

@media only screen and (max-width: 1750px) {
  .stone-grid-view.match-pair-grid-view .stone-grid-item {
    width: calc(33.33% - 10px);
  }
}

@media only screen and (max-width: 1450px) {
  .stone-grid-view.match-pair-grid-view .stone-grid-item {
    width: calc(33.33% - 10px);
  }
}

@media only screen and (max-width: 1080px) {
  .stone-grid-view.match-pair-grid-view .stone-grid-item {
    width: calc(50% - 10px);
  }
}
@media only screen and (max-width: 767px) {
  .searchInnerFilterMain {
    .dn-list-tags {
      flex-direction: column;
      margin: auto;
      &.m-list-result {
        flex-wrap: wrap;
        margin: auto;
        .ant-tag {
          padding: 5px 16px;
          margin-bottom: 5px;
          margin-right: 0px;
        }
        .dn-list-tags-title {
          margin: auto;
        }
      }
    }
  }
}
@media only screen and (min-width: 750px) and (max-width: 780px) {
  .ml-ipad-10 {
    display: flex !important;
    margin-right: 10px !important;
  }

  .diamond-list-select {
    display: flex;
    overflow-x: auto;
    width: calc(100% - 80px);
    .selectStoneValueItem {
      display: flex;
      // flex-wrap: wrap;
      margin-right: 15px;
      align-items: center;
      display: -webkit-inline-box;
      span {
        &.selectStoneLabel {
          color: rgba(52, 52, 52, 0.5);
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 500;
          margin-right: 3px;
          display: block;
        }

        &.selectStopnValue {
          color: #307bea;
          font-size: 13px;
        }
      }
    }
  }
}
@media only screen and (max-width: 720px) {
  .stone-grid-view.match-pair-grid-view .stone-grid-item {
    width: 100%;
  }
}

@media only screen and (max-width: 1100px) {
  .layout-grid-view .stone-grid-item {
    width: 100%;
  }
}
