@import "./variable.less";

.notificationMainBlock{
    padding: 0 25px;
    width: 95%;
    margin: auto;
    .notificationTable{
        .notificationTableHead{
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid #e9e9e9;
            position: sticky;
            z-index: 1;
            background-color: #fff;
            top: -20px;
            .notifiHeadeItem{
                display: flex;
                justify-content: center;
                flex-direction: column;
                font-size: 14px;
                font-weight: 600;
                padding: 20px 0;
                text-align: center;
                white-space: nowrap;
                width: 16%; 
                color: #454545;
                &:first-child{
                    flex: 1;
                    font-size: 18px;
                    text-align: left;
                    width: 36%;
                }
            }
        }
        .notifiDetails{
            display: flex;
            flex-direction: row;
            .notifiDetailItem{
                display: flex;
                text-align: center;
                flex-direction: column;
                width: 16%;
                white-space: nowrap;
                border-bottom: 1px solid #e9e9e9;
                padding: 20px 0;
                cursor: pointer;
                .notificationtooltipBlock{
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    color: #000;
                }
                .notifiSelect{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    .ant-select{
                        margin: 0 10px 0 0;
                        &.ant-select-focused{
                            .ant-select-selection{
                                box-shadow: none;
                            }
                        }
                    }
                    .ant-select-selection-selected-value{
                        white-space: nowrap;
                        overflow: hidden;
                        display: block !important;
                        text-overflow: ellipsis;
                    }
                    .ant-select-arrow{
                        svg{
                            fill: @themeColor;
                        }
                    }
                }
                .notificationMTwoImg{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        margin: 0 10px;
                        &.volumeImg{
                            width: 20px;
                            height: 20px;
                        }
                    }
                    .requireFile{
                        color: rgb(220, 110, 123);
                        font-size: 20px;
                    }
                }
                img{
                    width: 25px;
                    margin: auto;
                    height: 25px;
                    cursor: pointer;
                }
                &:first-child{
                    flex: 1;
                    width: 36%;
                    text-align: left;
                }
            }
        }
    }
}

