@import '../../../../styles/variable.less';

.HeaderStripBlock {
  background: #fafafa;
  min-height: 40px;
  padding: 0 10px 0 45px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  // z-index: 100000;
  .HeaderStripLeft {
    display: flex;
    align-items: center;
    width: auto;
    .headerStripItem {
      margin-right: 15px;
      span {
        color: #000;
        font-weight: 600;
        &:first-child {
          font-size: 13px;
          margin-right: 5px;
        }
        &.headerStripValue {
          border: 1px solid @themeColor;
          border-radius: 3px;
          padding: 2px 4px;
          color: @themeColor;
          font-size: 13px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .HeaderStripRight {
    display: flex;
    // width: 140px;
    .headerStipIcon {
      margin-right: 25px;
      position: relative;
      img {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }
      &:after {
        content: '';
        width: 1px;
        height: 15px;
        background-color: #a7a7a7;
        position: absolute;
        right: -12px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
    .SelectLanguage {
      width: 114px;
      // width: 100px;
      border: 1px solid #000000 !important;
      display: block;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      background-color: #fff;
      border: 0px solid #d9d9d9;
      border-top-width: 1.02px;
      // border: 1px solid #e5e5e5;
      border-radius: 4px;
      outline: none;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      .formSelect {
        margin-bottom: 0;
      }
      .ant-select {
        width: 100%;
        border: none !important;
        .ant-select-selection {
          background-color: transparent !important;
          border: none !important;
          .ant-select-selection-selected-value {
            color: #000 !important;
          }
          .ant-select-arrow {
            right: 0px;
            svg {
              fill: #000;
            }
          }
          &:focus {
            outline: none;
            border: none;
            box-shadow: none;
          }
        }
        &:focus {
          outline: none;
          border: none;
          box-shadow: none;
        }
      }
    }
  }
  &.active {
    z-index: 10000;
  }
}
.salesPersonDropdown {
  // position: absolute;
  background: #fff;
  top: 100%;
  right: 0;
  padding: 10px;
  box-shadow: -5px -5px 16px 0px rgba(0, 0, 0, 0.09);
  border-radius: 0 0 10px 10px;
}
.m-header {
  .headerRightSide {
    .signUp {
      display: flex;
    }
    .signIn {
      display: flex;
    }
    // .lang-color {
    //   .lang-steps {
    //   }
    // }
    // .ant-select-dropdown-menu-item-active:hover {
    //   font-weight: 600;
    //   color: #000;
    // }
    .lang-color {
      li.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item {
        font-weight: 600;
        color: #000;
      }
      .ant-select-dropdown-menu {
        .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
        .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
          font-weight: 600;
          color: #000;
        }
        .ant-select-dropdown-menu-item:hover {
          font-weight: 600;
          color: #000;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .HeaderStripBlock {
    padding: 0 10px;
    width: 100%;
    .HeaderStripLeft {
      width: 100%;
      display: block;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .headerStripItem {
        margin: 10px 0;
        span {
          &:first-child {
            // font-size: 11px;
            // margin-right: 2px;
          }
        }
      }
      .headerStripValue {
        padding: 2px;
      }
    }
    .HeaderStripRight {
      display: block;
      // display: none;
      .SelectLanguage {
        .ant-select-focused .ant-select-selection,
        .ant-select-selection:focus,
        .ant-select-selection:active {
          box-shadow: none;
        }
        // .lang-steps{
        // }
      }
    }
  }
}
.lang-color {
  .ant-select-selection--single {
    .ant-select-selection__rendered {
      .ant-select-selection-selected-value {
        font-weight: 500;
        color: #000;
      }
    }
  }
}
.timeLagSelect {
  box-shadow: none !important;
  border: none !important;
  margin-right: 5px;
  .ant-select-selection {
    height: 21px;
    width: 60px;
    border-radius: 3px !important;
    border: 1px solid @themeColor;
    box-shadow: none !important;
    .ant-select-selection__rendered {
      line-height: 21px;
      margin-right: 16px;
      .ant-select-selection-selected-value {
        color: @themeColor;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .ant-select-arrow {
      color: #000;
      font-weight: 600;
      right: 4px;
      top: 0;
      bottom: 0;
      transform: translate(0, 0);
      width: 12px;
      height: 12px;
      margin: auto;
      svg {
        fill: @themeColor;
        width: 8px;
        height: 8px;
      }
    }
  }
}

@primary-color: #000;