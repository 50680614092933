@import "variable.less";

.quickSearchTopHeader{
    margin-bottom: 8px;
    h2{
        margin: 0;
    }
}
.quickSearchTable{
    width: 100%;
    overflow: auto;
    height:100%;
    table{
        width: 100%;
        tr{
            th{
                background-color: #f8f8f8;
                padding: 10px;
                border: 1px solid #e8e8e8;
                color: @themeLightColor;
                text-transform: uppercase;
                &:nth-child(3){
                    text-align: center;
                }
            }
            td{
                padding: 6px;
                border: 1px solid #e8e8e8;
                white-space: nowrap;
                min-width: 70px;
                &:hover{
                    background-color: @themeLightColor;
                    cursor: pointer;
                }
            }
            .quickSearchTitleTd{
                background-color: #f8f8f8;  
                &:hover{
                    background-color: #f8f8f8;
                }  
            }

        }
    }
}
.quickSearchMainWrapper{
    margin-top: 20px;
    height: 100%;
    .headerSelectOption{
        width: auto;
        background: #fff;
        min-height: 100%;
        display: flex;
        align-items: center;
    }
    .quickSearchRight{
        // height:calc(100vh - 122px);
        padding: 10px;
        // overflow: auto;
        &.checkImageWrapper{
            padding: 0;
        }
    }
}
.selectdValue{
    width: 100%;
    display: flex;
    padding: 0 15px;
    .selectValueItem{
        width: auto;
        margin-right: 10px;
        img{
            width: 10px;
            height: 10px;
            margin-right: 5px;
        }
        span{
            font-weight: 500;
            font-size: 14px;
            color: #000;
        }
    }
}

@media (min-width:768px){
    .mobileheader{
        display: none;
    }
}

@media (max-width:767px){
    .quickSearchTopHeader{
        display: none !important;
    }
    .quickSearchMainWrapper{
        margin-top: 10px;
    }
    .quickSearchFilter{
        .leftSelectItem {
            display: block;
            margin-right: 0;
            margin-bottom: 10px;
            .ant-select-selection{
                .ant-select-selection__rendered{
                    margin-left: 0;
                }
            }
        }
    }
    .selectdValue{
        display: -webkit-box;
        overflow: auto;
        &::-webkit-scrollbar{
            display: none;
        }
    }
}