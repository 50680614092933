@import 'variable.less';
.HeaderSticky {
  position: sticky;
  top: 0;
  z-index: 1000;
  // z-index: 7000;
  border-bottom: 1px solid #dbdbdb;
}
.dashbord-bg-color-white {
  background-color: #f3f3f3;
  // background-color: #f2ffe5;
  // background-color: #e6ffe8;
  // background-color: #ebecf0;
}
.headerTopMenu {
  // background: @themeColor;
  background: #fff;
  padding: 0 15px;
  background-size: 35%;
  position: relative;
  // background-image: url("../assets/svg/Header/header-bg.svg");
  // padding-bottom: 10px;
}
// .headerStrip{
//     width: 100%;
//     height: 10px;
//     background-image: url("../assets/svg/Dashboard/bg.svg");
// }
.headerWrapper {
  display: flex;
  // padding: 5px 0;
  padding: 10px 0px;
  align-items: center;
  // position: relative;
  z-index: 1;
  margin-left: 80px;
  // margin-left: 115px;
}
.headerLogo {
  width: 100px;
  text-align: center;
  a {
    width: auto;
    display: inline-block;
    font-size: 30px;
    color: #000;
    font-weight: 600;
    line-height: 39px;
  }
}
.headerSearchWrapper {
  width: 240px;
  margin-left: 15px;
  .headerSearch {
    width: 100%;
    position: relative;
    background-color: #f4f4f4;
    border-radius: 20px;
    padding: 9px 15px;
    height: 40px;
    input {
      width: 100%;
      background-color: transparent;
      border: none;
      color: #000;
      // padding-left: 18px;
      &::placeholder {
        color: #000;
      }
    }
    .headerSearchIcon,
    .headerSearchIcon2 {
      position: absolute;
      left: 10px;
      top: 0;
      bottom: 0;
      width: 16px;
      height: 16px;
      margin: auto;
      img {
        width: 16px;
        height: 16px;
        display: block;
      }
    }
    .headerSearchIcon2 {
      left: auto;
      right: 10px;
      cursor: pointer;
    }
  }
}
.headerLeft {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
}
.headerRightSide {
  // width: 40%;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;

  width: 100%;
  justify-content: flex-end;
}
.headerMenuOption {
  display: flex;
  width: 38%;
  justify-content: flex-start;
  align-items: center;
  &.headerOptionRight {
    width: auto !important;
    // height: 50px;
    height: 39px;
  }
  // width: calc(100% - 370px);
}
.headerIcon img.hoverIcon {
  display: none;
  opacity: 0;
  visibility: hidden;
  width: 0;
  cursor: pointer;
}
.headerIcon img.defulticon {
  opacity: 1;
  visibility: visible;
  width: 21px;
  cursor: pointer;
  display: block;
}
// .headerRightBlock.active{
//     .headerIcon{
//         img{
//             &.hoverIcon{
//                 display: block;
//             }
//             &.defulticon{
//                 display: none;
//             }
//         }
//     }
// }
.headerRightBlock {
  // width: 370px;
  display: flex;
  align-items: center;
  &.active {
    .headerIcon {
      span {
        color: #fff;
      }
      .defulticon {
        display: none;
        opacity: 0;
        visibility: hidden;
        width: 0;
      }
      .hoverIcon {
        display: block;
        opacity: 1;
        visibility: visible;
        width: 21px;
      }
    }
  }
}
.headerRightOption {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.headerIcon {
  display: flex;
  align-items: center;
  z-index: 8000;
  position: relative;
}
.headerIcon img {
  width: 21px;
  height: 21px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
.headerIcon .headerUserName {
  margin-left: 10px;
}
.headerIcon span {
  font-size: 12px;
  // color: rgb(255, 255, 255);
  color: #000000;
  // color: #5a5757;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  font-weight: 600;
}
.headerRightBlock {
  justify-content: flex-end;
}
.headerMeniInner > ul {
  display: flex;
  // position: relative;
  justify-content: center;
  margin: 0;
}
.headerMeniInner > ul li {
  list-style: none;
}
.headerMeniInner > ul li > ul.ant-menu {
  background-color: transparent;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 600;
  display: block;
  color: #ffffff;
  padding: 0 15px;
  position: relative;
  white-space: nowrap;
}
.headerMeniInner > ul li > ul.ant-menu-horizontal {
  border-bottom: 0px solid #e8e8e8;
  line-height: 36px;
}
.headerMeniInner > ul li > ul.ant-menu-horizontal .ant-menu-submenu-title:hover {
  color: #fff;
}
.headerMeniInner > ul li > ul.ant-menu-horizontal > .ant-menu-submenu:hover {
  color: #fff;
  border-bottom: 2px solid #ffffff;
}

.headerMeniInner > ul li a.menuItem {
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 600;
  display: block;
  color: rgb(255, 255, 255);
  padding: 0 15px;
  position: relative;
  white-space: nowrap;
}
.headerMeniInner > ul li .menuItem {
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 600;
  display: block;
  color: rgb(255, 255, 255);
  padding: 0 0px;
  position: relative;
  white-space: nowrap;
}
.headerMeniInner > ul li.active-sidebar a.menuItem,
.headerMeniInner > ul li:hover a.menuItem {
  font-weight: 600;
}
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
  color: #fff;
  border-bottom: 2px solid #ffffff;
}
.submenuItem ul li a:hover {
  background-size: 100% 1px;
}
// li.ant-menu-item {
//   display: inline-block;
//   position: relative;
//   padding-bottom: 3px;
//   margin-right: 10px;
// }
// li.ant-menu-item:after {
//   content: '';
//   display: block;
//   margin: auto;
//   height: 3px;
//   width: 0px;
//   background: transparent;
//   transition: width 0.5s ease, background-color 0.5s ease;
// }
// li.ant-menu-item:hover:after {
//   width: 100%;
// }
.ant-menu-item,
.ant-menu-submenu-title {
  padding: 0 8px;
}
.ant-menu-item > a:hover {
  color: #000;
  font-weight: 600;
  // border-bottom: 1px solid #000;
}
.ant-menu-item > .border-transaction {
  display: inline-block;
  color: #000;
  text-decoration: none;
}
.ant-menu-item > .border-transaction::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: #000;
  transition: width 0.5s;
}
.ant-menu-item > .border-transaction {
  width: 0%;
  display: table-caption;
}

.ant-menu-item > .border-transaction:hover,
.ant-menu-item > .border-transaction::after {
  //transition: width .3s;
  width: 100%;
}
.ant-menu-item > a,
.ant-menu-item > a:hover {
  line-height: 23px;
}
.ant-menu-item:active {
  background-color: #fff;
}
.ant-menu-item:hover,
.ant-menu-item-active,
// .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #000;
  font-weight: 600;
}
.headerMeniInner > ul li a.menuItem:after {
  content: '';
  width: 50px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -7px;
  margin: auto;
  border-radius: 10px 10px 0 0;
  z-index: 10000;
  opacity: 0;
}
.headerMeniInner > ul li.active a.menuItem:after {
  opacity: 1;
}
.headerMeniInner > ul li.active-sidebar a.menuItem:after,
.headerMeniInner > ul li:hover a.menuItem:after {
  opacity: 1;
}
.menuDropdown {
  &.main-dropdown {
    min-width: 160px !important;
    width: 160px;
    top: 175%;
    .submenuWrapper {
      min-width: 100%;
      height: auto;
      padding: 5px;
      .submenuItem {
        padding-right: 0px;
        ul {
          li {
            margin: 8px 8px;
            a {
              word-break: break-word;
            }
          }
        }
      }
    }
  }
}
.pr-relative {
  position: relative;
}
.menuDropdown {
  box-shadow: 0px 9px 12px 0px rgba(0, 0, 0, 0.09);
  /* display: none; */
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  width: 100%;
  z-index: 1000;
  background: #fff;
  // padding-top: 30px;
  // padding-bottom: 20px;
  padding-top: 0px;
  padding-bottom: 0px;
  border-bottom: 1px solid #ddd;
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: 0.2s ease-in;
}

.headerMeniInner > ul li:hover .menuDropdown {
  visibility: visible;
  /* display: block; */
  opacity: 1;
  height: auto;
  // top: 50px;
}
.headerMeniInner ul#links > li {
  // overflow: hidden;
  // padding-bottom: 9px;
}
.submenuWrapper {
  display: flex;
  max-width: 80%;
  margin: 0 auto;
  min-width: 1420px;
  padding: 0 10px;
}
.submenuItem {
  flex-basis: 100%;
  padding-right: 50px;
}
.subMenuImage {
  flex: 0 0 50%;
}
.submenuItem h3 {
  font-size: 16px;
  color: #000;
  line-height: 1.71;
  letter-spacing: normal;
  margin-bottom: 8px;
  font-weight: 600;
}
.submenuItem ul {
  // padding: 0 10px 0 0;
  padding: 0 0px 0 0;
  margin: 0;
}
.submenuItem ul li {
  list-style: none;
  margin: 8px 0;
}
.submenuItem ul li a {
  // display: inline-block;
  position: relative;
  font-size: 14px;
  color: #000;
  background: linear-gradient(0deg, @themeColor, @themeColor) 0 100% no-repeat;
  transition: background-size 0.5s;
  background-size: 0 1px;
  display: inline !important;
}
.submenuItem ul li a:hover {
  background-size: 100% 1px;
}
.submenuImageContent .sibmenuImg {
  height: 100px;
}
.submenuImageContent .sibmenuImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.submenuContent {
  margin-top: 15px;
}
.submenuContent .submenudropTitle {
  color: #000;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}
.submenuContent .submenuLink {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  margin-top: 5px;
}
.submenuContent .submenuLink img {
  width: 12px;
  height: 12px;
  margin-left: 2px;
}
.HeaderRightSidebar {
  background-color: #fff;
  width: 0;
  z-index: 7000;
  position: fixed;
  top: 61px;
  right: 0;
  transition: 0.4s ease;
  visibility: hidden;
  height: 100vh;
  -webkit-box-shadow: -2px 0px 16px 0px rgba(0, 0, 0, 0.09);
  box-shadow: -2px 0px 16px 0px rgba(0, 0, 0, 0.09);
  &.active {
    transition-delay: 0.5s;
    visibility: visible;
    width: 383px;
  }
  .headerRightOverlay {
    content: '';
    position: fixed;
    background-color: transparent;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .headerRightSidebarTop {
    height: 80px;
    // background: @themeSecondColor;
    background-color: transparent;
    top: -80px;
    position: absolute;
    width: 100%;
  }
}
.dropdownRightSide {
  overflow: auto;
  height: calc(100% - 60px);
  background: #fff;
  position: relative;
}
.dropdownUserIcon {
  display: flex;
  align-items: center;
  padding: 15px;
  position: sticky;
  top: 0;
  background: #fff;
  .userProfileLogo {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 100%;
    }
  }
  .userProfileContent {
    width: calc(100% - 70px);
    h2 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 0;
      line-height: 16px;
    }
    p {
      color: #000;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}
.userDetailList {
  padding: 25px;
  ul {
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 30px;
      .userDetailListItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .detailItemName {
          font-size: 16px;
          color: #000;
          font-weight: 600;
        }
        img {
          width: 12px;
          height: 12px;
        }
      }
      .uerDetailBasic {
        display: flex;
        align-items: center;
      }
    }
  }
}
.userDetailBasicItem {
  width: auto;
  margin-right: 10px;
  display: flex;
  align-items: center;
  span {
    font-size: 12px;
    &:first-child {
      font-size: 12px;
      text-transform: uppercase;
      color: #555;
      margin-right: 5px;
      font-weight: 500;
    }
    &:last-child {
      color: #000;
      font-weight: 600;
    }
  }
}
.notFoundDataSidebar {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  height: 100%;
  align-items: center;
  h2 {
    width: 100%;
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
    color: #000;
  }
  p {
    font-size: 16px;
    margin: 0;
    color: #212121;
  }
}

.cartDropdownWrapper {
  padding: 15px;
  .cartDropdownWraperItem {
    display: flex;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .cartDrodownImg {
      width: 120px;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .cartDropdownContent {
      // display: flex;
      width: calc(100% - 140px);
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: column;
      .cartDrodownTop {
        width: 100%;
        h2 {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 5px;
          font-weight: 600;
        }
        .cartDiamondDetail {
          // display: flex;
          // flex-wrap: wrap;
          // align-items: center;
          .cartDropdownPrice {
            margin-right: 5px;
            display: flex;
            font-size: 12px;
            span {
              &:first-child {
                text-transform: uppercase;
                color: #555;
                margin-right: 5px;
                font-weight: 500;
                min-width: 50px;
              }
              &:last-child {
                color: #000;
                font-weight: 600;
              }
            }
          }
        }
      }
      .cartDropdownLink {
        flex-direction: column;
        display: flex;
        flex: 0 1 auto;
        a {
          color: #000;
          font-weight: 500;
        }
      }
    }
  }
}
.cartDropdownItem {
  height: calc(100vh - 195px);
  overflow: auto;
}
.cartDropdownSummary {
  .cartDropdownSummaryItem {
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
    span {
      font-size: 14px;
      color: #000;
    }
  }
  .cartDropdoownButton {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #000;
    font-size: 15px;
    span {
      color: #fff;
    }
    button {
      color: #fff;
      background-color: transparent;
      border: none;
    }
  }
}

.notificationDropdown {
  padding: 15px;
  .notificationItem {
    margin-bottom: 20px;
    .notificationTitle {
      font-size: 16px;
      text-decoration: underline;
      color: #000;
      font-weight: 600;
      margin-bottom: 15px;
    }
    .notificationInner {
      .notificationDropdownDetail {
        margin-bottom: 10px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        &:last-child {
          border-bottom: none;
        }
        h2 {
          font-size: 16px;
          font-weight: 600;
          line-height: 18px;
          margin-bottom: 5px;
        }
        span {
          color: #000;
          text-transform: capitalize;
          font-size: 12px;
          font-weight: 500;
        }
        &.readMessage {
          h2 {
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

.covidStrip {
  background: @themeSecondColor;
  padding: 5px 0;
  text-align: center;
  position: relative;
  span {
    color: #fff;
    &.closeImg {
      position: absolute;
      right: 10px;
      cursor: pointer;
      width: 20px;
      height: 20px;
      background: #fafafa;
      border-radius: 100%;
      top: 0;
      bottom: 0;
      display: flex;
      margin: auto;
      cursor: pointer;
      img {
        width: 8px;
        height: 8px;
        margin: auto;
      }
    }
  }
}
.ant-tooltip {
  width: auto !important;
}
.searchWrapper {
  .searchWrapperInner {
    position: fixed;
    width: 0;
    background: @headerStripColor;
    padding: 10px;
    top: 0;
    height: 50px;
    z-index: 100000;
    display: flex;
    flex-wrap: wrap;
    right: 0;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;
    .searchBox {
      width: 50%;
      margin: auto;
      .ant-input-affix-wrapper {
        margin: 0 10px;
      }
      input {
        width: 100%;
        background-color: transparent;
        border: none;
        margin-left: 0;
        padding-right: 0;
        color: #fff;
        &::placeholder {
          color: #fff;
        }
        &:focus {
          outline: none;
        }
        &.ant-input:focus {
          box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3) !important;
        }
      }
      .ant-input-suffix {
        display: none;
      }
      .searchIcon {
        img {
          width: 16px;
          height: 16px;
        }
      }
      .searchIconClose {
        margin-left: 20px;
        cursor: pointer;
        img {
          width: 14px;
          height: 14px;
        }
      }
    }
    .searchDropdown {
      position: absolute;
      margin: auto;
      width: 50%;
      left: 0;
      right: 0;
      background: #f1f1f1;
      padding: 15px;
      border-radius: 0 0 10px 10px;
      top: 50px;
      box-shadow: -1px 12px 16px 0px rgba(0, 0, 0, 0.19);
      h2 {
        font-weight: 600;
        margin: 0 0 10px 0;
        font-size: 16px;
      }
      ul {
        padding: 0 15px;
        li {
          list-style: none;
          color: #555;
          line-height: 26px;
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
  }
  &:before {
    content: '';
    width: 100%;
    position: fixed;
    height: 100%;
    background: rgba(0, 0, 0, 0.58);
    left: 0;
    top: 0;
    display: none;
  }
  &.active {
    &:before {
      display: block;
    }
    .searchWrapperInner {
      width: 100%;
      opacity: 1;
      // left: 0;
      margin: auto;
      visibility: visible;
    }
  }
}
.headerTopMenu {
  &.active {
    // .HeaderRightSidebar {
    //   top: 61px;
    // }
    .cartDropdownItem {
      height: calc(100vh - 225px);
    }
  }
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #f4ecec;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .headerMenuOption {
    // order: 2;
    // width: auto;
  }
  .headerTopMenu {
    .headerWrapper {
      .headerLeft {
        // order: 1;
        // width: auto;
      }
    }
    .headerMeniInner > ul li a.menuItem {
      padding: 0 5px;
    }
    .headerRightSide {
      // order: 3;
      // width: auto;
    }
  }
  .headerIcon {
    .headerUserName {
      display: none;
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .headerIcon {
    .headerUserName {
      display: none;
    }
  }
  .headerTopMenu {
    .headerMeniInner > ul li a.menuItem {
      padding: 0 3px;
      font-size: 13px;
    }
  }
}
