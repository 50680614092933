.Row {
  z-index: 0;

  &:hover {
    .Cell {
      background-color: #ffffff !important;
    }

    :global(tr) {
      :global(td) {
        background-color: #ffffff !important;
      }
    }
  }

  &Hidden {
    display: none;
  }
}

.Cell {
  overflow-x: auto;
  padding: 0 0 0.25rem 0 !important;
  :global(.table-collapse) {
    position: relative;
    width: 0;
  }
}

@primary-color: #000;