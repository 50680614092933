@import '../../styles/variable.less';

.scrollarea .scrollbar-container.vertical .scrollbar {
  margin-left: auto !important;
  background: rgb(248, 248, 248) !important;
  width: 4px !important;
}

.sidebarHeader {
  display: grid;
  position: fixed;
  top: 0px;
  z-index: 10002;
  border-radius: 0 0px 0px 0;
  box-shadow: 5px 5px 5px rgba(55, 55, 55, 0.68);
  background: #fff;
  padding: 10px 0;
  width: 80px;
  height: 100vh;

  .ant-menu-inline-collapsed {
    width: 60px;
  }

  .menubutton {
    position: absolute;
    left: 95px;
    margin-top: 15px;
  }

  .ant-menu {
    background-color: transparent !important;
  }

  .ant-btn {
    border: none;
    background-color: #fff;
    padding: 0px;
    width: 25px;
    height: 25px;
    color: #000;
  }

  .ant-menu-inline {
    border-right: 0px solid #e8e8e8;
  }

  .sidebarLogo {
    display: block;
    margin: 0px auto 0px auto;
    width: 100%;

    img {
      margin: auto;
      height: auto;
    }
  }

  .sideBarScroll {
    height: calc(100vh - 166px);
  }

  .sidebarMenuList1 {
    height: auto;
  }

  .sidebarMenuList {
    margin: 25px 0 0 0;
    height: calc(~'100vh - 80px');
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: thin;

    ul {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      transition: height 0.3s, width 0.3s;
      margin: 0;
      padding: 0;
      height: 85%;

      li {
        position: relative;
        border-radius: 100%;
        width: 100%;
        list-style: none;
        text-align: center;

        a {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          padding: 12px 4px;
          height: 100%;
          text-transform: uppercase;
          word-wrap: break-word;
          font-size: 8.5px;
          font-weight: 600;

          &.active {
            background-color: #000;

            p {
              color: #fff;
            }

            .defualtImg {
              display: none;
            }

            .activeImg {
              display: block;
            }
          }

          &.pt-15 {
            padding-top: 15px;
          }

          &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            background-color: #000;
            width: 50%;
            height: 1px;
            content: '';
          }

          p {
            padding-top: 3px;
            line-height: 1.5;
            color: #000;
            font-size: 8px;
            font-weight: 700;
          }

          span {
            width: 100%;

            &.menu_link {
              position: absolute;
              top: 50%;
              left: 50px;
              transform: translateY(-50%);
              z-index: 999;
              border-radius: 5px;
              box-shadow: 0 3px 5px rgba(55, 55, 55, 0.2);
              background: #a5a3a3;
              padding: 0px;
              width: 0;
              overflow: hidden;
              color: #303030;
              font-size: 13px;

              span {
                color: #000;
              }
            }
          }

          &:hover {
            color: #555;

            img {
              transform: scale(1.2);
            }

            span {
              &.menu_link {
                display: block;
                transition: all 0.2s ease-in-out;
                padding: 5px 10px;
                width: max-content;
              }
            }
          }
        }

        img {
          transition: all 0.2s linear;
          margin: auto;
          width: 55px;
          height: 22px;

          &.img-65 {
            width: 60px;
            height: 26px;
          }

          &.defualtImg {
            display: block;
          }

          &.activeImg {
            display: none;
          }
        }

        &:last-child {
          &::after {
            content: none;
          }
        }
      }
    }

    &.sidebarListBottom {
      display: none;
      align-items: flex-end;
      margin: 0;
      border-radius: 0 0 30px 0;
      height: auto;

      ul {
        border-bottom-right-radius: 0px;
        background-color: #555;
        width: 100%;

        li {
          a {
            &:hover {
              color: #000;
            }

            span {
              color: #000;
            }
          }
        }
      }
    }
  }
}

.content-area {
  margin-left: 80px;
  width: calc(100% - 80px);
  // width: calc(100vw - 80px);
  height: 100vh;
}

@media screen and (min-width: 1360px) and (max-width: 1440px) {
  .sidebarHeader {
    .sidebarMenuList {
      margin: 11px 0;
    }
  }
}

@media screen and (min-height: 600px) and (max-height: 670px) {
  .sidebarHeader {
    .sidebarMenuList {
      width: 80px;
      height: calc(100vh - 90px);
      overflow: hidden;
      overflow-y: auto;
      scrollbar-width: thin;

      ul {
        li {
          a {
            padding: 10px 8px;
          }

          p {
            font-size: 9px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .content-area {
    margin-left: 0 !important;
    width: 100%;
    height: calc(100vh - 60px);
    overflow: auto;

    .notificationDropdown {
      padding: 0px 8px;

      .notificationItem {
        margin-bottom: 0px;
        overflow: auto;

        .notificationInner {
          padding-bottom: 25px;
        }

        .m-notifications-sticky {
          position: absolute;
          bottom: -3px;
          background-color: #f3f3f3;
          padding: 10px;
          width: 100%;
        }
      }
    }
  }
}

@primary-color: #000;