@import 'variable.less';

.diamondSearch {
  padding-top: 2px;
  .ant-select-selection {
    background-color: #000;
    color: #fff;
    .ant-select-arrow {
      .ant-select-arrow-icon {
        svg {
          fill: #fff;
        }
      }
    }
  }
  // .diamondSearch {
  //   .diamondSearchWrapper {
  //     .diamondSearchItem {
  //       .w-20pr {
  //         .diamondSearchLabel {
  //           width: 180px;
  //         }
  //       }
  //     }
  //   }
  // }

  // .diamondSearchItem {
  //   .w-20pr {
  //     .diamondSearchDetail {
  //       width: 100% !important;
  //     }
  //   }
  // }
  .plr-5 {
    padding: 0px 10px;
  }
  // .br-1 {
  //   align-items: flex-start !important;
  //   border-right: 1px solid #ddd;
  // }

  .diamondSearchItem {
    &.makeGroup {
      &.full-width {
        width: 100%;
        .diamondSearchFourBlock {
          .w-20pr {
            width: 20%;
          }
        }
      }
    }
  }
  .diamondSearchWrapper {
    ::-webkit-scrollbar {
      width: 3px;
    }
    scrollbar-width: thin;
    .diamondSearchItem {
      display: flex;
      flex-wrap: wrap;
      padding: 5px 15px;
      align-items: center;
      border-bottom: 1px solid #ddd;
      &.fancyColorBlock {
        border-bottom: none;
        padding: 0;
        align-items: normal;
        .diamondSearchLabel {
          padding: 5px 15px;
        }
        .diamondSearchDetail {
          padding: 5px 15px 5px 0;
          width: calc(100% - 245px);
          margin-left: 15px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          border-bottom: 1px dashed #dedede;
        }
      }
      &.makeGroup {
        // width: 100%;
      }
      .haBlock,
      &.floBlock,
      &.labBlock,
      .brilliance {
        // width: 100%;
        &.haBlock {
          margin-bottom: 8px;
        }
        .diamondSearchLabel {
          margin-bottom: 0;
        }
      }
      .colorTab {
        margin-top: 5px;
        a {
          font-size: 12px;
          // font-size: 13px;
          color: #000;
          font-weight: 400;
          padding-right: 5px;
          margin-right: 5px;
          border-right: 1px solid #ddd;
          &.active {
            font-weight: 600;
            color: @themeColor;
            text-decoration: underline;
          }
          &:last-child {
            border-right: none;
          }
        }
      }
      .diamondSearchLabel {
        width: 180px;
        label {
          font-weight: 600;
          color: #000;
          font-size: 13px;
          // font-size: 14px;
        }
        // .colorTab {
        //   margin-top: 5px;
        //   a {
        //     font-size: 13px;
        //     color: #000;
        //     font-weight: 400;
        //     padding-right: 5px;
        //     margin-right: 5px;
        //     border-right: 1px solid #ddd;
        //     &.active {
        //       font-weight: 600;
        //       color: @themeColor;
        //       text-decoration: underline;
        //     }
        //     &:last-child {
        //       border-right: none;
        //     }
        //   }
        // }
      }
      .diamondSearchDetail {
        width: calc(100% - 180px);
        position: relative;
        &.newArrivalBlock {
          .fromToValue {
            width: calc(100% - 190px);
          }
        }
        .parameterBlockInner {
          .diamondSearchInnerblock:nth-child(4) {
            border-left: none;
            padding-left: 0;
          }
        }
        .caratRange {
          .diamondSearchCaratRange {
            width: 30%;
          }
        }
        &.fullWidthBlock {
          width: 100%;
          .diamondSearchInnerblock {
            // margin-top: 10px;
          }
        }
        .diamondSearchShape {
          display: flex;
          // flex-wrap: wrap;
          // height: 79px;
          height: auto;
          margin: 0;
          overflow: hidden;
          line-height: 21px;
          width: 100%;
          li {
            width: 10%;
            list-style: none;
            text-align: center;
            // margin: 5px 0;
            border: 1px solid #b3b3b3;
            // border: 1px solid #ddd;
            padding: 10px 5px;
            cursor: pointer;
            margin: 0 3px;
            border-radius: 4px;
            background-color: #fff;
            // border-right: none;
            span {
              display: block;
              font-size: 11px;
              // font-size: 13px;
              // font-size: 14px;
              font-weight: 500;
              text-transform: capitalize;
              color: #000;
              line-height: normal;
            }
            img {
              height: 33px;
              // height: 38px;
            }
            .diaondShapeName {
              margin-top: 5px;
            }
            .diamondShape {
              display: block;
              height: 33px;
              // height: 38px;
            }
            .diamondShapehover {
              display: none;
              height: 33px;
              // height: 38px;
            }

            &.active {
              background-color: #000;
              color: #000;
              border: 1px solid #605f5f;
              svg {
                path {
                  fill: #fff;
                  stroke: #fff;
                }
              }
              span {
                color: #fff;
              }
              .diamondShape {
                display: none;
              }
              .diamondShapehover {
                display: block;
              }
            }
          }
          &.two_row {
            flex-wrap: wrap;
            li {
              width: calc(6% - 3px);
              margin-bottom: 6px;
              &.more_shapes {
                display: flex;
                align-items: center;
                justify-content: center;
                background: #414143;
                span {
                  color: #fff;
                }
                &:hover {
                  background: transparent;
                  border-color: #414143;
                  span {
                    color: #414143;
                  }
                }
              }
            }
          }
          &.active {
            height: auto;
          }
        }
        .shapeMoewLess,
        .smallMoreLess {
          right: 0;
          border: 1px solid #ddd;
          height: 79px;
          display: flex;
          align-items: center;
          width: 107px;
          font-weight: 500;
          color: #000;
          justify-content: center;
          position: absolute;
          top: 0;
          cursor: pointer;
          &.smallMoreLess {
            width: 50px;
            height: 33px;
            margin: 2px 0;
          }
        }
        .diamondSearchCaratRange {
          width: 20%;
        }

        .fromToValue {
          display: flex;
          justify-content: space-between;
          .fromToValueInput {
            width: 48%;
            // padding: 7px 0;
            border-bottom: 1px solid #d2d3d4;

            margin-right: 10px;
            // width: 100px;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
            align-items: center;
            padding-top: 0;
            label {
              font-size: 13px;
              // font-size: 14px;
              font-weight: 600;
              color: #a0a2a6;
            }
            input {
              width: 100%;
              border: none;
              text-align: left;
              color: #000;
              font-weight: 500;
              font-size: 15px;
              // font-size: 16px;
              // padding-left: 15px;
              padding: 4px 10px;
              &::placeholder {
                font-size: 13px;
                // font-size: 14px;
              }
            }
            &.focus {
              border-bottom: 1px solid @themeColor !important;
            }
          }
        }
        .addCaratButton {
          min-width: 120px;
          text-align: center;
          margin-left: 25px;
        }
        .diamondSearchList {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 0;
          &.whiteColor {
            // height: 35px;
            // overflow: hidden;
            // width: calc(100% - 50px);
            &.active {
              height: auto;
            }
          }
          li {
            text-transform: uppercase;
            list-style: none;
            padding: 5px 10px;
            // border: 1px solid #ddd;
            // background-color: #fafafa;
            border: 1px solid #b3b3b3;
            background-color: #fff;
            margin: 2px;
            color: #000;
            font-weight: 500;
            min-width: 50px;
            cursor: pointer;
            text-align: center;
            border-radius: 4px;
            line-height: 20px;
            // min-width: 80px;

            &.active {
              background-color: #000 !important;
              color: #fff !important;
              border: 1px solid #000;
            }
            // &.active {
            //   box-shadow: 0 0 7px 0px rgba(192, 158, 124, 0.15);
            //   // font-size: 15px;
            //   color: #000;
            //   background-color: @tableBackGroundColor;
            //   border: 1px solid @themeColor;
            //   // border: 1px solid @themeColor;
            //   // background-color: @tableBackGroundColor;
            // }
            &.diamondSearchCaratRange {
              background: #70646400;
              border: 0;
              cursor: auto;
              text-align: left;
              border-radius: 0;
              display: flex;
              align-items: center;
              padding-top: 0 !important;
              padding-bottom: 0 !important;
              width: max-content;
              // border-left: 1px solid #a9a9a9;
              // border-right: 1px solid #a9a9a9;
              .fromToValue {
                .fromToValueInput {
                  margin-right: 10px;
                  width: 100px;
                  display: flex;
                  align-items: center;
                  padding-top: 0;
                  background-color: #fff;
                  &:last-child {
                    margin-right: 0;
                  }
                  input {
                    width: 100%;
                  }
                }
              }
              .addCaratButton.commonButton {
                padding: 2px 10px;
                min-width: 100px;
                margin-left: 15px;
              }
            }
          }
        }
        .searchSelectValue {
          display: flex;
          // justify-content: space-between;
          .searchSelectItem {
            width: 49%;
            align-items: center;
            display: flex;
            padding: 6px 0;
            // border-bottom: 1px solid #d2d3d4;
            .searchSelectLabel {
              font-size: 13px;
              // font-size: 14px;
              font-weight: 600;
              color: #a0a2a6;
            }
            .ant-select {
              width: 100%;
              border: none;
              border-bottom: 1px solid #d2d3d4;
              border-radius: 0;
              .ant-select-selection-selected-value {
                float: right;
                padding-right: 15px;
                font-weight: 500;
                color: #000;
                font-size: 15px;
                // font-size: 16px;
              }
              .ant-select-selection {
                border: none;
                box-shadow: none;
                &:active {
                  box-shadow: none;
                  border: none;
                }
              }
              &:hover,
              &:active {
                box-shadow: none;
              }
            }
            &.multiSelect {
              width: 100%;
              padding: 2px 0;
              .ant-select-selection {
                padding-bottom: 0;
                .ant-select-selection__rendered {
                  height: 35px;
                  .ant-select-selection__placeholder {
                    margin-left: 0;
                    font-size: 13px;
                    // font-size: 14px;
                    font-weight: 600;
                    color: #a0a2a6 !important;
                  }
                  ul {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    li {
                      &.ant-select-selection__choice {
                        background: #fafafa;
                        border-radius: 5px;
                        color: #000;
                      }
                    }
                  }
                }
              }
            }
            &.singleInput {
              width: 100%;
              label {
                font-size: 13px;
                // font-size: 14px;
                font-weight: 600;
                color: #000;
                width: 120px;
                margin-right: 15px;
                text-align: left;
              }
              input {
                border: none;
                border-bottom: 1px solid #d2d3d4;
                width: calc(100% - 135px);
                color: #000;
                font-size: 13px;
                // font-size: 14px;
                font-weight: 500;
                padding: 6px 0;
                text-align: left;
              }
            }
          }
        }
        .diamondSearchInnerblock {
          width: 32%;
          margin: 5px 0.5% 5px 0;
          padding-right: 0.5%;
          position: relative;
          // border-right: 1px solid #ddd;
          // &:after{
          //   content: "";
          //   width: 1px;
          //   height: calc(100% + 10px);
          //   top: 0;
          //   background-color: #ddd;
          //   right: -20px;
          //   position: absolute;
          // }
          .smallLabel {
            font-size: 13px;
            // font-size: 14px;
            margin-right: 10px;
            color: #000;
            white-space: nowrap;
            min-width: 120px;
            text-align: left;
          }
          .tool {
            padding: 5px;
          }
          .diamondSearchCaratRange {
            width: calc(100% - 130px);
            padding-left: 5px;
          }
          .searchSelectItem {
            width: 49%;
          }
        }
      }
    }
    .diamondSearchFourBlock {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      // border-bottom: 1px solid #ddd;
      .diamondSearchItem {
        border-bottom: none !important;
      }
    }
    .whiteColorBox {
      .diamondSearchList {
        width: 100%;
      }
    }
    .colorGroupShow {
      width: 100%;
      .diamondSearchLabel {
        width: 120px;
      }
      .diamondSearchList {
        width: 100%;
        border-bottom: 1px dashed #dedede;
        padding-bottom: 5px;
        margin-bottom: 5px !important;
      }
    }
    .full-width {
      width: 100%;
    }
    .matchpairSearch {
      .diamondSearchItem {
        align-items: center !important;
        // align-items: center !important;
        &.labBlock {
          display: block;
        }
        &.floBlock {
          display: block;
          width: 18%;
          // width: 60%;
          // &.w-33 {
          //   width: 33.33%;
          //   border-right: 1px solid #ddd;
          //   border-left: 0px solid #ddd;
          //   padding: 5px 0px;
          //   border-bottom: 0px solid #ddd;
          // }
          .diamondSearchLabel {
            width: 180px !important;
          }
          .diamondSearchDetail {
            width: calc(100% - 180px);
          }
        }
        // &.labBlock {
        //   &.w-33 {
        //     width: 33.33%;
        //     border-left: 0px solid #ddd;
        //     border-bottom: 0px solid #ddd;
        //   }
        // }
        // .diamondSearchFourBlock {
        //   &.w-33 {
        //     width: 33.33%;
        //     border-left: 1px solid #ddd;
        //     border-bottom: 0px solid #ddd;
        //   }
        // }
        &.location {
          width: 70%;
          .diamondSearchLabel {
            width: 180px !important;
          }
          .diamondSearchDetail {
            width: calc(100% - 180px);
          }
        }
        &.shadeSpearetBlock {
          width: 30%;
          border-left: 1px solid #ddd;
          padding-left: 10px;
          .diamondSearchDetail {
            .diamondSearchList {
              justify-content: center;
            }
          }
        }
        &.labBlock {
          width: 18%;
          // width: 40%;
          .diamondSearchLabel {
            width: 90px !important;
          }
          .diamondSearchDetail {
            width: calc(100% - 90px);
          }
        }
      }
    }
    .hideShowBlock {
      background-color: #fafafa;
      border-top: 1px solid #ddd;
      padding: 15px;
      color: #000;
      font-size: 13px;
      // font-size: 14px;
      font-weight: bold;
      display: flex;
      cursor: pointer;
      text-transform: uppercase;
      justify-content: space-between;
      img {
        height: 14px;
        width: 14px;
      }
      &.adv {
        cursor: default;
        > div {
          cursor: pointer;
          background-color: #000;
          // background-color: #404040;
          color: #fff;
          padding: 5px 15px;
          border-radius: 5px;
          img {
            margin-left: 10px;
          }
        }
      }
    }
    .fullBlockInnerBlock {
      // padding: 0 15px;
    }
    .b-b-0 {
      border-bottom: none;
    }
  }
}
.width-100 {
  width: 100% !important;
}
.makeGroup {
  width: 45%;
  display: block !important;
}
.labBlock,
.floBlock,
.location {
  width: 18%;
  align-items: flex-start !important;
  border-left: 1px solid #ddd;
  .diamondSearchLabel {
    width: 100% !important;
  }
  .diamondSearchDetail {
    width: 100% !important;
  }
}
.searchSimpleDrpdowwn {
  &.ant-select {
    border: 1px solid #ddd;
    background-color: #fafafa;
    height: 32px;
    margin: 2px;
    .ant-select-selection {
      background-color: transparent;
      border: none;
      width: 130px;
      .ant-select-selection-selected-value {
        color: #000 !important;
        font-size: 13px;
        // font-size: 14px;
        font-weight: 500;
      }
      .ant-select-arrow {
        svg {
          width: 10px;
          height: 10px;
        }
      }
      &:focus,
      &:hover {
        box-shadow: none;
      }
    }
    &:focus,
    &:hover {
      box-shadow: none;
    }
  }
}
.searchTopBox {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  padding-top: 7px 0px;
  align-items: flex-end;
  margin-bottom: 13px;
  box-shadow: 1px 5px 5px -1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 5px 5px -1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 5px 5px -1px rgba(0, 0, 0, 0.2);
}
.searchTopInputValue {
  margin-right: 10px;
  .searchTopBlock {
    width: 300px;
    padding: 8px 0;
    input {
      width: 100%;
      // border: none;
      border: 1px solid @inputBorder;
      color: #000;
      padding: 6px 10px;
      border-radius: 8px !important;
      height: auto;
      // background: @themeLightColor;
      // background: #f2ffe5;
      background: #e5e5e5;
      &::placeholder {
        font-size: 13px;
        // font-size: 14px;
        font-weight: 500;
        color: #000;
      }
      &:hover {
        outline: none;
        box-shadow: none;
      }
      &:focus {
        outline: none;
        box-shadow: none;
        border: 1px solid @themeColor;
      }
    }
  }
}
.caratTagButton {
  margin-top: 5px;
  .addedCarat {
    padding: 3px 5px;
    border: 1px solid @themeColor;
    background-color: @themeLightColor !important;
    color: @themeColor !important;
    margin-right: 0;
    margin-left: 5px;
    svg {
      fill: @themeColor;
    }
  }
}
.brilliance,
.haBlock {
  width: 50%;
}

.multiSelectDropdown {
  img {
    margin-left: 5px;
    width: 12px;
    height: 12px;
  }
}
.selectOptionDropdown {
  background: #fff;
  padding: 10px;
  box-shadow: -5px -5px 16px 0px rgba(0, 0, 0, 0.09);
  border-radius: 0 0 10px 10px;
  .ant-checkbox-wrapper {
    display: block;
    margin-left: 0;
  }
}
.diffrentBlockWrapper {
  .threeBlockPart {
    width: 30%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    border-left: 1px solid #ddd;
    padding: 0 10px;
    .commonLabel {
      width: 120px;
      margin-right: 10px;
      // text-align: right;
    }
    .threeBlockDetail {
      width: calc(100% - 130px);
      padding-left: 5px;
    }
    &:first-child {
      width: 40%;
    }
    ul {
      margin: 0;
      margin-top: 0;
      // justify-content: center;
    }
    &:first-child {
      padding-left: 0;
      border-left: none;
    }
    .threeInnerBlock {
      padding-left: 10px;
      // width: 50% !important;
      margin-right: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
.smallLabelInner {
  color: #000;
  font-size: 13px;
  // font-size: 14px;
  padding: 0 10px;
  margin-bottom: 5px;
  font-weight: 600;
  text-decoration: underline;
}
.caratBoxSize {
  li {
    min-width: 100px !important;
    padding: 5px 5px !important;
  }
}
// .inclusiveBlock{
//   .diamondSearchDetail{
//     .diamondSearchList {
//       li{
//         width: 55px;
//       }
//     }
//   }
// }
.advancrSearchBlock {
  border-bottom: 1px dashed #ddd;
  padding-bottom: 10px;
  margin-top: 20px;
}
.shadeBlock {
  padding: 0 !important;
  align-items: normal !important;
  .shapdeInnerBlock {
    width: 50%;
    padding: 5px 15px;
  }
  .milkyBlock {
    width: 25%;
    padding: 5px 15px;
    border-left: 1px solid #ddd;
    display: flex;
    align-items: center;
    .diamondSearchLabel {
      width: 100px !important;
      // text-align: right;
      margin-right: 15px;
    }
  }
  .eyecleanBlock {
    width: 25%;
    padding: 5px 15px;
    border-left: 1px solid #ddd;
    display: flex;
    align-items: center;
    .diamondSearchLabel {
      width: 100px !important;
      text-align: right;
      margin-right: 15px;
    }
  }
}
.diamondSearchShape li svg {
  fill: #000000;
  // fill: #4c4d4f;
}
// .diamondSearchShape {
//   li{
//     span{
//       &.diamondShape{
//         background-image: url("../assets/img/diamond_sprite_new.png");
//         width: 100%;
//         height: 28px;
//         object-fit: contain;
//       }
//     }
//   }
// }
.select-diamonds {
  .ant-select {
    .ant-select-selection {
      border: none !important;
      box-shadow: none !important;
    }
  }
}
.upd_image_wrap {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
  .thumbnail {
    margin-right: 10px;
    width: auto;
    height: 70px;
    a {
      overflow: visible;
      text-indent: 0;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 12px;
        height: 12px;
        fill: #fff;
      }
    }
  }
}
.bgt_range {
  width: 100%;
  margin-bottom: 15px;
  .smallLabel {
    color: #000;
  }
  .diamondSearchCaratRange {
    .inputBlock {
      display: flex;
      align-items: center;
      margin-bottom: 0;
      .inputWrapper {
        margin-left: 20px;
      }
    }
  }
}
.diamondSearch {
  .diamondSearchItem {
    .diamondSearchDetail {
      .diamondSearchList {
        li {
          &:hover {
            background-color: #000;
            color: #fff;
            border: 1px solid #000;
          }
        }
      }
    }
  }
}

.diamondSearch {
  .diamondSearchWrapper {
    .diamondSearchItem {
      .diamondSearchDetail {
        .diamondSearchShape {
          li {
            &:hover {
              background-color: #000;
              color: #fff;
              // color: #000;
              border: 1px solid #605f5f;
              span {
                color: #fff;
                svg {
                  path {
                    fill: #fff;
                    stroke: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .diamondSearch {
    .diamondSearchWrapper {
      .diamondSearchItem {
        .diamondSearchDetail {
          .searchSelectValue {
            .searchSelectItem {
              &.singleInput {
                label {
                  width: auto;
                }
                input {
                  width: 100%;
                }
              }
            }
          }
          &.newArrivalBlock {
            .fromToValue {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .shadeBlock {
    .shapdeInnerBlock,
    .milkyBlock,
    .eyecleanBlock {
      width: 100%;
    }
    .milkyBlock,
    .eyecleanBlock {
      flex-wrap: wrap;
      .diamondSearchLabel {
        text-align: left;
        width: 100% !important;
      }
    }
  }
}

.thumbnail {
  width: 50px;
  height: 50px;
  position: relative;
  img {
    max-width: 100%;
    max-height: 100%;
  }
  a {
    display: block;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 3px;
    right: 3px;
    background: #c00;
    overflow: hidden;
    text-indent: -9999px;
  }
}
