@import "./variable.less";

.cl-hero--homepage  {
    background-color: @bgLightColor;
    padding: 50px 0 0 0;
    overflow: hidden;
}
.cl-wrapper{
    overflow: hidden;
    margin-bottom: 100px;
    padding: 50px 0;
    position: relative;
}

.cl-hero__media {
    height: 100%;
    margin: auto;
    max-width: 1800px;
    position: relative;
    width: 100%
}

.cl-hero__media:before {
    content: "";
    display: block;
    padding-top: 51.64319%;
    width: 100%
}

.cl-hero__media > * {
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0
}

@media only screen and (min-width: 640px) {
    .cl-hero__media {
        position: relative
    }

    .cl-hero__media:before {
        content: "";
        display: block;
        padding-top: 55.65566%;
        width: 100%
    }

    .cl-hero__media > * {
        bottom: 0;
        left: 0;
        margin: auto;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0
    }
}

@media only screen and (min-width: 1000px) {
    .cl-hero__media {
        min-height: 475px;
        position: relative
    }

    .cl-hero__media:before {
        content: "";
        display: block;
        padding-top: 56.22222%;
        width: 100%
    }

    .cl-hero__media > * {
        bottom: 0;
        left: 0;
        margin: auto;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0
    }
}

.cl-hero--homepage .cl-hero__media {
    height: auto;
    margin-bottom: -198px;
    margin-left: 13.3574%;
    position: relative;
    width: calc(86.6426% + 40px);
    z-index: 1
}

.cl-hero--homepage .cl-hero__media > * {
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    background-color: #000;
}

.cl-hero--homepage .cl-hero__media {
    float: right;
    margin-bottom: 0;
    margin-left: -45.2007%;
    margin-right: calc(-50vw - -50%);
    max-width: 1210px;
    overflow: hidden;
    position: relative;
    width: 65%;
}

.cl-hero--homepage .cl-hero__media > * {
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0
}

@media only screen and (min-width: 1800px) {
    .cl-hero--homepage .cl-hero__media {
        width: calc(74.17103% + 260px)
    }
}

.cl-hero--homepage .cl-hero__media .cl-hero__play-btn {
    bottom: auto;
    left: 50%;
    right: auto;
    top: 34%;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    z-index: 1;
    top: 0;
    position: absolute;
    bottom: 0;
}

.cl-hero__contents {
    width: 50%;
    bottom: 50px;
    position: absolute;
    margin-left: 6.45724%;
    z-index: 1;
    p{
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        max-width: 80%;
        background: #565656;
        line-height: 24px;
        padding: 10px;
    }
    .clContentRight{
        width: calc(100% - 280px);
    }
}
.clContentBox{
    display: flex;
    align-items: center;
}
.cl-hero__title {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    color: #000;
    font-size: 35px;
    text-align: left;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 53px;
    margin-bottom: 30px;
    background: #fff;
    padding: 10px 15px;
    display: inline-block;
}


.play-btn {
    background-color: hsla(0, 0%, 100%, .15);
    border: 2px solid #fff;
    border-radius: 50%;
    display: inline-block;
    height: 70px;
    opacity: 1;
    -webkit-transition-duration: .35s;
    -o-transition-duration: .35s;
    transition-duration: .35s;
    -webkit-transition-property: background-color, border;
    -o-transition-property: background-color, border;
    transition-property: background-color, border;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    width: 70px
}

.play-btn:before {
    border-bottom: 6px solid transparent;
    border-left: 10px solid #fff;
    border-top: 6px solid transparent;
    content: "";
    display: block;
    height: 0;
    margin: 41% auto;
    width: 0
}

.play-btn-overlay:hover .play-btn, .play-btn:hover {
    background-color: #fff
}

.play-btn-overlay:hover .play-btn:before, .play-btn:hover:before {
    border-left-color: #4e5764
}

.play-btn-overlay {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    top: 0
}
.cl-hero--homepage .cl-hero__video {
    display: block;
}
.cl-hero__img, .cl-hero__media img, .cl-hero__media video, .cl-hero__video {
    height: 100%;
    width: 100%;
}
.userImageArties{
    width:300px;
    height: 300px;
    border-radius: 100%;
    margin-bottom:30px;
    margin-right: 50px;
    img{
        width: 100%;
        height:100%;
        border-radius: 100%;
        object-fit: cover;
    }
}
.video-react-controls-enabled{
    padding:0 !important;
}
.TopImageBlock{
    max-width: 85%;
    margin:00px auto 20px auto;
    box-shadow: 0 0 20px 0 #917f6c;
    position: relative;
    .topImageBox{
        width: 100%;
        height: 450px;
        position: relative;
        &:after{
            content: "";
            width: 100%;
            height: 100%;
            background: rgba(0,0,0 ,49%);
            position: absolute;
            left: 0;
            top: 0;
        }
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .topBlockContent{
        position: absolute;
        bottom: 50px;
        left:-50px;
        h2{
            color: #fff;
            font-size: 54px;
            line-height: 55px;
            font-weight: 600;
        }
        p{
            color: #fff;
            line-height: 24px;
            font-size: 16px;
            max-width: 50%;
        }
    }
}



