@import 'variable.less';

.footerSection {
  background-image: url('../assets/svg/Dashboard/bg.svg');
  // background-repeat: no-repeat;
  position: relative;
  background-position: center;
  padding: 15px 0;
  /* border-top: 2px solid rgb(192 158 124 / 18%); */
  background-color: rgba(56, 56, 56, 0.62);
  position: relative;
  &:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba(56, 56, 56, 0.62);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .homeContainer {
    position: relative;
    z-index: 1;
    // padding-top: 50px;
  }
}
.footerWrapper {
  // padding-bottom: 50px;
  display: flex;
  padding: 0 15px;
  align-items: center;
  justify-content: space-between;
  ul {
    padding: 0;
    margin: 0;
    width: auto;
    display: flex;
    li {
      list-style: none;
      line-height: 30px;
      color: #fff;
      font-weight: 600;
      a {
        text-transform: uppercase;
        color: #fff;
        font-size: 14px;
        letter-spacing: 0.5px;
        margin: 0 10px;
        font-weight: 500;
        &:hover {
          color: @themeColor;
        }
      }
    }
  }
}
.tracklistButton {
  .commonButton {
    padding: 2px 5px;
  }
}

// new dashboard
.dashboardMainWrapper {
  // margin-top: -58px;
  // background-color: #fafafa;
  // min-height: 100vh;
  padding-right: 15px;
}
.dashboardBoxWrapper {
  background: #fff;
  // padding:15px;
  // max-width: 1640px;
  margin: auto;
  align-items: flex-start;
}
.fp-tableCell {
  margin-top: -60px;
}
.dashboardBannerBox {
  width: calc(100% - 20%);
  // width: calc(100% - 40%);
  padding: 0 10px;
  // padding: 0 30px;
  // background: #fafafa;
  // height: calc(100vh - 80px);
  // display: contents;
  .w-50 {
    width: 100%;
    // width: 50%;
  }
  .deal_block_wrap {
    width: 100%;
    // width: 29%;
    // height: 30px;
    .deal_block_container {
      border-radius: 10px;
      width: 100%;
      // width: calc(~'40% - 10px');
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      margin: 10px auto;
      background-color: #e9e9e9;
      // background-color: rgba(65, 65, 67, 0.02);
      border: 1px solid rgba(65, 65, 67, 0.09);
      border-radius: 10px;
      padding: 10px;
      height: 473px;
      .slider_block {
        background: #f2f2f2;
        // background: #fff;
        border-radius: 10px;
        padding: 10px 0px;
        height: 452px;
        // width: calc(~'50% - 5px');
        // margin-bottom: 10px;
        .title_block {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 3px;
          padding: 0px 15px 5px;
          border-bottom: 2px solid rgb(224, 224, 224);
          h3 {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 15px;
            margin-bottom: 0px;
          }
          a {
            font-weight: 500;
            font-size: 14px;
            text-transform: uppercase;
            text-decoration: underline;
          }
        }
        .slick-next {
          right: 10px;
        }
        .slick-prev {
          left: 10px;
        }
        .slick-prev,
        .slick-next {
          background: #fff;
          padding: 0 5px;
          width: 30px;
          height: 30px;
          z-index: 1;
          top: 35%;

          box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
          &:before {
            font-size: 13px;
            color: #000;
          }
        }
        .daimondGridViewItem {
          width: 100%;
          border-left: 2px solid #e0e0e0;
          // display: flex !important;
          // justify-content: space-between;
          // align-items: center;
          padding: 0 20px;
          margin-top: 10px;
          .DiamondGridViewImg {
            // width: 50%;
            // height: 250px;
            // background: #fafafa;
            // position: relative;

            // position: relative;
            // cursor: pointer;
            // border-radius: 10px;
            // padding-top: 10px;
            width: 100%;
            height: 250px;
            background: #fafafa;
            border-radius: 10px 10px 0 0;
            position: relative;
            display: -webkit-flex;
            display: flex;
            img {
              // width: 100%;
              // height: 100%;
              // border-radius: 10px;
              // height: auto;
              // height: 520px;
              // object-fit: cover;
              // border-radius: 10px;

              width: 100%;
              height: 100%;
              object-fit: contain;
              border-radius: 10px 10px 0 0;
              margin: auto;
              background-color: #f2f2f2;
            }
          }
          .gridViewContent {
            display: flex;
            flex-wrap: wrap;
            // width: 50%;
            padding: 10px 0px 10px 0px;
            .gridLeftContent {
              width: 100%;
              text-align: left;
              .st_id {
                justify-content: space-between;
                .st-two {
                  display: inline-flex;
                }
                .st-ml-5 {
                  margin-left: 5px;
                  font-size: 12px;
                  font-weight: 500;
                }
              }
              h2 {
                font-size: 16px;
                font-weight: 800;
                margin-bottom: 5px;
                line-height: 20px;
              }
              a {
                margin-bottom: 5px;
                color: #000;
                display: block;
                font-weight: 500;
                text-decoration: underline;
                &.labName {
                  text-decoration: none;
                }
              }
              p {
                margin: 0;
                font-size: 14px;
                color: #353535;
              }
              // }
              // .gridRightContent {
              // width: 40%;
              // text-align: right;
              span {
                display: block;
                color: #000;
                font-weight: 500;
                font-size: 16px;
                &.st_amt {
                  font-weight: 700;
                  margin-top: 10px;
                }
              }
              .statusShow {
                display: -webkit-flex;
                display: flex;
                -webkit-align-items: center;
                align-items: center;
                -webkit-justify-content: flex-end;
                justify-content: flex-end;
                font-size: 14px;
                margin-top: 5px;
                .statusIcon {
                  margin-left: 5px;
                  width: 8px;
                  height: 8px;
                  border-radius: 100%;
                  display: block;
                  background: #6bc950;
                  &.red {
                    background-color: #db1c1c;
                  }
                }
              }
            }
          }
        }
        // &.dashboard_right{
        //   margin-top: 0;
        //   height: auto;
        //   position: relative;
        //   top: 0;
        //   .diamond_data{
        //     padding: 0;
        //     margin-bottom: 0;
        //     .diamond_block_wrap{
        //       height: calc(~'100vh - 333px');
        //     }
        //   }
        // }
      }
      .bannerImg {
        width: 60%;
        padding-left: 0px;
        // width: calc(~'60% - 10px');
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .banner_container {
          // margin-bottom: 10px;
          width: 100%;
          display: block;
          &:first-child {
            margin-bottom: 10px;
          }
        }
        img {
          max-width: 100%;
          border-radius: 10px;
          width: 100%;
        }
      }
    }
  }
  #fullpage,
  section {
    max-width: 780px;
    margin: auto;
  }
  .section {
    // max-width: 800px;
    margin: 10px auto;
    // margin: 40px auto;
    background-color: rgba(65, 65, 67, 0.02);
    border: 1px solid rgba(65, 65, 67, 0.09);
    border-radius: 10px;
    padding: 10px;
    // border-bottom: 1px solid #dbdbdb;
  }
}
.dashboardRight {
  // width: calc(100% - 270px);
  width: 100%;
  margin-right: 20px;
  background: #fff;
  // padding: 10px;
}
.dashboardCard {
  .fp-tableCell {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &.leftRight {
    background-color: #fff;
    .dashboardContentBox {
      order: 1;
      .dashboardSliderDetail {
        padding: 25px 35px 25px 25px;
      }
    }
    .dashboardSliderBlock {
      order: 2;
    }
    .dashboardSlider {
      .dashboardSliderSmallSlider {
        order: 2;
      }
      .dashboardBigSlider {
        order: 1;
        margin-left: 0;
        // margin-right:15px;
      }
    }
  }
}
.dashboardSliderBlock {
  // width: calc(100% - 290px);
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  // padding: 15px;
  // top: 13px;
  // padding: 15px 0 0 0;
  position: relative;
}
.dashboardContentBox {
  width: 30%;
}
.topBottomBlock {
  .dashboardCard {
    width: 50%;
    .dashboardImageBlock {
      // height: 400px;
    }
    .dashboardSliderBlock,
    .dashboardContentBox {
      width: 100%;
      .dashboardSliderDetail {
        padding: 15px 15px;
      }
      .dashboardSlider {
        flex-wrap: wrap;
        .dashboardSliderSmallSlider {
          width: 100%;
          order: 2;
          height: auto;
          .slick-list {
            height: auto !important;
          }
          .dashboardSliderSmallImage {
            img {
              height: 80px;
            }
          }
        }
        .dashboardBigSlider {
          width: 100%;
          order: 1;
          margin: 0;
          .dashboardSliderImage {
            height: 600px;
          }
        }
      }
    }
  }
}

.dashboardTopBanner {
  // height: 100%;
}
@media screen and (max-width: 767px) {
  .dashboardBannerBox {
    .section {
      margin: 20px 0;
    }
  }
}
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .dashboardBoxWrapper {
    flex-wrap: wrap;
  }
  .dashboardMenu,
  .DashboardQuickMenu {
    width: 100%;
    // width: 50%;
    position: relative;
    top: 0;
  }
  .dashboardMenu {
    order: 2;
  }
  .dashboardBannerBox {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .dashboardBoxWrapper {
    flex-wrap: wrap;
    .section {
    }
  }
  .dashboardMenu {
    width: 100%;
    position: relative;
    top: 0;
  }
  // .dashboardMenu,
  .DashboardQuickMenu {
    width: 50%;
    position: relative;
    top: 0;
  }
  .dashboardMenu {
    order: 2;
  }
  .dashboardBannerBox {
    width: 100%;
    .m-w-100 {
      flex-wrap: wrap;
      .deal_block_wrap {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .dashboardBoxWrapper {
    flex-wrap: wrap;
  }
  .dashboardBannerBox {
    width: 100%;
    padding: 0 15px;
  }

  .dashboardMenu,
  .DashboardQuickMenu {
    width: 100%;
    position: relative;
    top: 0;
  }
  .dashboardMenu {
    display: none;
  }
}
