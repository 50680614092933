@import 'variable';

.dna-stone-title {
  white-space: pre;
}

.qrCodeOption {
  position: fixed;
  z-index: 1;
  right: 20px;
  bottom: 18px;
  width: 90px;
  padding: 12px 0 10px 0;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 0 14px 2px rgba(0, 0, 0, 0.19);
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  img,
  canvas {
    width: 50px;
    height: 50px;
    margin: auto;
    object-fit: contain;
  }
  span {
    color: #000;
    text-transform: uppercase;
    font-size: 11px;
    text-align: center;
    display: block;
    margin-top: 5px;
    width: 100%;
    line-height: 16px;
    font-weight: 600;
  }
}
.dnaContainerBox {
  padding: 54px 15px 15px 15px;
  max-width: 1400px;
  margin: auto;
}
.dnaWrapper {
  min-height: ~'calc(100vh - 54px)';
  // min-height: ~'calc(100vh + 54px)';
  width: 39%;
  // width: 40%;
}
.dnaWrapperBox {
  &:first-child {
    width: 100%;
  }
  width: 57%;
  // margin: 10px;
  border-radius: 10px;

  &.threeBlock1 {
    width: 41.66%;
  }
  &.threeBlock2 {
    width: 16.66%;
  }
}
.dnaWrapperBox {
  .dnaImageBlock {
    .dnaImageSwipe {
      .dnaLeftBox {
        .dnaLeftBoxImage {
          // height: calc(100vh - 110px);
          min-height: 50vh;
        }
      }
    }
  }
}
.dnaImageSwipe {
  .slick-track {
    margin: auto;
  }
  .sliderMainImage {
    display: flex;
    align-items: center;
    // height: 500px;
    .slick-slide[aria-hidden='false'] {
      min-width: 1px;
    }
  }

  .imageSliderFullBlock {
    .separator {
      width: 100%;
      border-top: 1px dashed #7a7a7a !important;
      margin: 1rem 0;
    }
  }

  .slider-video,
  .imageSliderFullBlock {
    text-align: center;
    display: flex;
    flex-direction: column;
    // min-height: 440px;
    // overflow: hidden;
    overflow-y: auto;
    max-height: ~'calc(100vh - 200px)';
    // height: calc(100vh - 205px);

    &.multiimg {
      overflow: hidden;
      overflow-y: auto;
      max-height: 440px;
      flex-direction: column;
      img {
        max-height: 280px;
      }
    }

    embed,
    iframe,
    img,
    div[data-vjs-player] {
      max-height: max-content;
      object-fit: contain;
      max-height: 100%;
      max-width: 100%;
      margin: auto;
    }
    embed,
    iframe {
      height: calc(100% - 100px);
      min-height: 540px;
      width: auto;
      min-width: 100%;
    }

    .magnifier {
      width: 100%;
      margin: auto;
      img {
        width: 400px;
      }
    }

    &.width-100 {
      iframe {
        width: 100%;
        height: 650px;
      }
    }
  }
  .imageSliderSmallBox {
    position: relative;
    height: 60px;
    cursor: pointer;
    display: flex;
    border: 1px solid #ddd;
    border-radius: 5px;
    img {
      width: 60% !important;
      height: 60% !important;
      margin: auto !important;
    }
  }
  .imageSliderSmallBlock {
    padding-right: 5px;
    img,
    iframe {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .imageVideoIcon {
    position: absolute;
    bottom: 3px;
    right: 3px;
    text-align: right;
    background: #000000;
    border-radius: 3px;
    padding: 6px;
    line-height: 1;
    font-size: 12px;
    color: #ffffff;
    display: flex;
    img {
      width: 12px;
      height: 12px;
    }
  }
  .imageVideoTitle {
    font-size: 13px;
    display: block;
    margin-top: 5px;
    color: #454545;
    white-space: pre-wrap;
    text-align: center;
  }
  .sliderSmallImage {
    padding: 10px 24px;
    .slick-slide {
      height: auto;
      &.slick-current {
        .imageSliderSmallBlock {
          .imageSliderSmallBox {
            border: 2px solid @themeColor;
            padding: 2px;
          }
        }
      }
    }
  }
}
.threeBlock1 {
  .sliderSmallImage {
    .slick-slide {
      &.slick-cloned {
        display: none;
      }
    }
  }
}
.dnaLeftBox {
  .dnaLeftBoxImage {
    border: 1px solid #ddd;
    border-radius: 10px;
  }
}
.dnaLeftBoxImage {
  width: 100%;
  .imageSliderFullBlock {
    .magnifier {
      height: 100% !important;
    }
    img {
      width: 100% !important;
      height: 100%;
    }
  }
}
.dnaImageTop {
  padding: 11px;
  font-size: 18px;
  border-bottom: 1px solid #eaeaea;
  margin: 0 0 10px 0;
  cursor: pointer;
  span {
    display: block;
    font-weight: 600;
    color: #000;
    text-align: center;
  }
  &.mainBox {
    background: @themeColor;
    margin: 0 0 10px 0;
    border: none;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    font-size: 16px;
    span {
      color: #fff;
      text-align: center;
    }
    .arjiv_logo {
      position: absolute;
      left: 10px;
      top: 5px;
      img {
        width: 140px;
      }
    }
  }
}
.dnaLeftRightDetil {
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  // height: calc(100vh - 100px);
  overflow: auto;
  .dnaDetailBlock {
    background: #fafafa;
    width: 100%;
    padding: 5px 10px;
    align-items: flex-start;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px solid #ddd;
    .mainHeadingTitle {
      width: 100%;
      margin-bottom: 3px;
      font-size: 12px;
      // font-size: 14px;
      line-height: 16px;
      padding: 5px 0;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eaeaea;
    }
  }
}
.dnaDetailBlock {
  padding: 10px;
  .detail {
    padding: 2px 5px;
    margin-bottom: 0px;
    width: 50%;
    display: flex;
    position: relative;
    span {
      color: #000;
      display: block;
      font-size: 12px;
      line-height: 15px;
      &:first-child {
        // margin-right: 5px;
        // letter-spacing: 0.5px;

        width: 115px;
        margin-right: 5px;
        font-size: 12px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
      &:last-child {
        font-weight: 500;
        word-break: break-word;
        width: calc(100% - 120px);
      }
    }
    &.width-100 {
      span {
        width: auto;
      }
    }
    &:nth-child(even):after {
      content: '';
      position: absolute;
      width: 1px;
      bottom: 0;
      top: 0;
      margin: auto;
      right: 5px;
      height: 115%;
      background-color: #ececec;
    }
    &:last-child::after {
      content: none;
    }
  }
  &.firstDetail {
    .detail:nth-last-child(2),
    .detail:last-child {
      width: 100%;
      span {
        &:first-child {
          width: 140px;
        }
        &:last-child {
          width: calc(100% - 145px);
        }
      }
    }
  }
}
.dnaJourenySticky {
  position: sticky;
  background-color: #fafafa;
  top: 0;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.diamondJourneyBlock {
  display: flex;
  justify-content: space-between;
  align-items: normal;
  flex-wrap: wrap;
  padding: 10px;
  .diamondJourneyItem {
    margin-bottom: 20px;
    width: calc(50% - 10px);
    margin: 5px;
    height: auto;
    text-align: center;
    border: 1px solid #f2f2f2;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    .diamondJourneyTitle {
      margin-bottom: 10px;
      span {
        background-color: #fafafa;
        color: #000;
        font-weight: 600;
        font-size: 16px;
      }
    }
    .diamondJourneyImage {
      margin: auto;
      width: 100%;
      height: 200px;
      border-radius: 10px;
      .magnifier {
        height: 100% !important;
      }
      img,
      iframe {
        border-radius: 10px;
        width: 100%;
        height: 100%;
        border: none;
        object-fit: cover;
      }
    }
  }
}
.dnaDetailShareOption {
  display: flex;
  position: absolute;
  right: 5px;
  top: 4px;
}
.dnaShareOption {
  width: auto;
  height: 35px;
  background-color: @themeColor;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  margin: 0 2px;
  img {
    margin: auto;
    width: 16px;
    height: 16px;
  }
  span {
    margin-left: 10px;
  }
}
.dnaWrapperBox .slick-prev,
.dnaWrapperBox .slick-next {
  width: 34px;
  height: 34px;
  border: 1px solid #ddd;
  border-radius: 100%;
  left: 15px;
  cursor: pointer;
  z-index: 1;
  background-color: #fff !important;
}
.dnaWrapperBox .slick-next {
  left: auto;
  right: 15px;
}
.dnaWrapperBox .sliderSmallImage .slick-prev,
.dnaWrapperBox .sliderSmallImage .slick-next {
  top: 50px;
}
.dnaWrapperBox .slick-prev::before,
.dnaWrapperBox .slick-next::before {
  content: '';
  background-image: url('../assets/svg/DNA/left-arrow.svg');
  width: 14px;
  height: 14px;
  background-size: 100%;
  opacity: 1;
  display: block;
  margin: auto;
}
.dnaWrapperBox .slick-next::before {
  transform: rotate(180deg);
}
.dnaWrapperBox .slick-prev:hover {
  background-color: #fff;
}
.similarStoneScroll {
  height: 670px;
  overflow: auto;
}
.similarStoneBlock {
  .similarStoneBlockItem {
    width: 100%;
    padding: 10px;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
    span {
      display: block;
      text-align: center;
      margin-top: 10px;
      color: #000;
    }
  }
}
.no-msg {
  &.no-image {
    width: 100%;
    display: inline-block;
    width: 100%;
    display: inline-block;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    max-width: 100%;
    padding: 10px;
    height: 500px;
    // min-height: -40px;
    text-align: center;
  }
}
.no-msg {
  min-width: 100%;
  width: 100%;
  font-size: 18px;
  color: #000;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  height: 100%;
  margin: auto;
}

.dnaImageSwipe {
  .download_all {
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    text-align: center;
    a {
      background: #414143;
      color: #fff;
      padding: 7px 15px;
      border-radius: 100px;
      img {
        margin-right: 5px;
      }
    }
  }
}
.downlaod_data {
  border-top: 1px solid #ddd;
  padding: 15px;
  text-align: center;
  p {
    font-weight: 600;
    font-size: 18px;
    color: #000;
    margin-bottom: 15px !important;
  }
  .dwn_liks {
    display: flex;
    justify-content: center;
    a {
      margin: 0 5px;
      img {
        width: 30px;
      }
    }
  }
  &.two_part {
    display: flex;
    justify-content: space-between;
    .stone_part {
      width: 50%;
      &:last-child {
        border-left: 1px solid #ddd;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .dnaContainerBox {
    padding: 50px 15px 0 15px;
    // padding: 90px 15px 0 15px;
    .dnaWrapper {
      .dnaWrapperBox {
        &.threeBlock1 {
          width: 40%;
        }
        &.threeBlock2 {
          width: 20%;
        }
      }
      .dnaImageSwipe {
        .imageSliderFullBlock {
          // height: 420px;
          .magnifier {
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .similarStoneScroll {
    height: 540px;
  }
}

@media (max-width: 768px) {
  .dnaContainerBox {
    padding: 90px 15px 15px 15px;
    // padding: 70px 15px 15px 15px;
    // padding: 110px 15px 0 15px;
    .flexWrap {
      flex-wrap: wrap;
    }
    .dnaWrapper {
      flex-wrap: wrap;
      width: 100%;
      min-height: calc(100% + 0px);
      // height: 100%;
      .dnaWrapperBox {
        width: 100%;
        margin: 10px 0;
      }
      .dnaImageSwipe {
        // .sliderMainImage {
        //   height: calc(100vh - 205px);
        // }
        .imageSliderFullBlock {
          .magnifier {
            img {
              width: 100%;
            }
          }
        }
        .sliderTwoImageBlock {
          .imageSliderFullBlock {
            height: auto;
          }
        }
      }
    }
    .similarStoneScroll {
      height: 470px;
      .no-msg {
        min-height: 470px;
      }
    }
    .dnaWrapperBox {
      margin: 0;
      width: 100%;
    }
    .dnaLeftRightDetil {
      .dnaDetailBlock {
        width: 100%;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        .detail {
          width: 50%;
          // width: 100%;
        }
      }
    }
    .dnaLeftBoxImage {
      .imageSliderFullBlock {
        img {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
    .diamondJourneyBlock {
      flex-wrap: wrap;
      .diamondJourneyItem {
        width: 100%;
      }
    }
    .dnaJourenySticky {
      .dnaImageTop {
        text-align: center;
        cursor: pointer;
      }
      iframe {
        height: 400px;
      }
    }
  }
  .dnaImageTop {
    &.mainBox {
      text-align: center;
      span {
        &.dna-stone-title {
          white-space: break-spaces;
          font-size: 14px;
        }
      }
    }
    .dnaDetailShareOption {
      position: relative;
      margin: auto;
      right: 0;
      left: 0;
      width: max-content;
    }
  }
  .qrCodeOption {
    display: none;
  }
  .dnaImageTop.mainBox {
    padding: 5px;
    .arjiv_logo {
      position: relative;
      left: auto;
      top: auto;
    }
  }
  .m-w-100 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .deal_block_wrap {
      width: 100%;
    }
    .w-50 {
      width: 100%;
    }
  }
  .dashboardMenu,
  .DashboardQuickMenu {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .dnaContainerBox {
    .dnaLeftRightDetil {
      .dnaDetailBlock {
        .detail {
          width: 100%;
        }
      }
    }
  }
  .m-w-100 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .deal_block_wrap {
      width: 100%;
    }
    .w-50 {
      width: 100%;
    }
  }
}
.sliderMainImage {
  > .slick-list {
    width: 100%;
    min-width: 100% !important;
  }
}
