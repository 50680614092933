@import "../../../styles/variable.less";

.leftSelectItem,
.selectValueItem{
    position: relative;
    display: flex;
    width: auto;
    align-items: center;
    overflow: hidden;
    margin-right: 10px;
    // svg.sropdownIcon{
    //     position: absolute;
    //     right: 0;
    //     width: 14px;
    //     height: 14px;
    // }
    .leftSelectTitle{
        display: flex;
        justify-content: space-between;
        h2{
            font-size: 12px;
            font-weight: 600;
            color: #000 !important;
            margin: 0;
            white-space: nowrap;
        }
        .reloadImg{
            width: 16px;
            height: 16px;
            cursor: pointer;
        }
    }
    .leftMultiSelect{
        width: 100%;
        border: none;
        position: relative;
        z-index: 1;
        .ant-select-selection{
            width: 100%;
            background: transparent;
            border: none;
            padding: 8px;
            height: auto;
            border-radius: 10px !important;
            display: flex;
            align-items: center;
            &.ant-select-selection{
                padding: 0;
                overflow: hidden;
                &.ant-select-selection--multiple{
                    .ant-select-selection__rendered{
                        ul {
                            li{
                                margin:2px;
                            }
                        }
                    }
                }
            }
            .ant-select-selection__rendered{
                margin-bottom: 0;
                margin-left: 5px;
                line-height: 22px;
                // height: 18px;   
                ul{
                    display: flex;
                    align-items: center;
                    li{
                        margin: 0 2px !important;
                        background: transparent;
                        border: none;
                        height: auto;
                        line-height: normal;
                    }
                }
            }
            
        &:focus,
        &:active{
            outline: none;
            box-shadow: none !important;
        }
        }
        &:hover{
            box-shadow: none;
        }
    }
    
    .SelectDateRange{
        width: 100%;
        .ant-calendar-picker-input{
            background: #fff;
            border: none;
            border-radius: 10px !important;
            padding: 7px 10px;
            height: auto;
            &.ant-input{
                color: #000;
                &::placeholder{
                    color: #000;
                }
                &:focus{
                    box-shadow: none !important;
                }
            }
            &:hover{
                border: none !important;
            }
        }
    }
}
.selectImageValue{
    display: flex;
    flex-wrap: wrap;
    .selectValueItem{
        margin-right: 10px;
        margin-bottom: 10px;
        min-width: calc(~"33.33% - 10px");
    }
    .selectShapeValue{
        width: 100%;
        display: flex;
        align-items: center;
        .slectShape{
            background-color: #eceff9;
            width: auto;
            min-width: 50px;
            text-align: center;
            padding: 8px 10px;
            border-radius: 5px;
            margin: 5px;
            cursor: pointer;
        }
    }
}
.datePickerDropdown {
    .ant-calendar-today{
        .ant-calendar-date{
            background-color: @themeLightColor;
            border: 1px solid @themeColor;
            color:@themeColor;
        }
    }
}
@primary-color: #000;