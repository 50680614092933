/* .loading-indicator:before {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100000000;
  background: rgba(255, 255, 255, 0.15);
  cursor: progress;
  width: 100%;
  height: 100%;
  content: '';
  background-image: url('../svg/loader.svg');
} */
.loading-indicator:before {
  content: '';
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 100000000009;
  background-image: url('../svg/loader.svg');
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-position: center;
  /* 50% 50% no-repeat rgb(249, 249, 249); */
}
.loader-inner {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('../svg/loader.svg') 50% 50% no-repeat rgb(249, 249, 249);
}
.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('../svg/loader.svg') 50% 50% no-repeat rgb(249, 249, 249);
}

/* .loading-indicator:after { */
/* display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000000001000;
  margin: auto;
  background-image: url('../svg/loader.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  width: 110px;
  height: 64px; */

/* animation: zoom-in-zoom-out 1s infinite;
  content: '';
  -webkit-animation: zoom-in-zoom-out 1s infinite; */
/* } */

.progress {
  cursor: progress;
}

/* @keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.3, 0.3);
    background-position: center;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
  }

  50% {
    transform: scale(1, 1);
    background-position: center;
  }

  100% {
    transform: scale(0.3, 0.3);
    background-position: center;
    -webkit-transform: scale(0.3, 0.3);
    -moz-transform: scale(0.3, 0.3);
    -ms-transform: scale(0.3, 0.3);
    -o-transform: scale(0.3, 0.3);
  }
} */

.fullLoader #loader {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.fullLoader #loader .loader-inner {
  position: relative;
  z-index: 2;
}

.fullLoader #loader:after {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  content: '';
}

.rightSticky {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  margin: auto;
  height: 385px;
}

.rightstickyBlock {
  position: relative;
  width: auto;
  min-width: 40px;
}

.rightstickyWrappers {
  position: absolute;
}

.rightstickyItem {
  display: block;
  position: relative;
  right: 0px;
  transition: all 0.5s ease-in-out;
  margin: 5px 0;
  border-radius: 5px 0 0 5px;
  background: #39613e;
  cursor: pointer;
  padding: 5px 10px;
  width: auto;
  color: #fff;
  font-size: 14px;
  -webkit-transition: all 0.5s ease-in-out;
}

.hoverRight {
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
}

.count {
  display: flex;
  position: absolute;
  top: 0px;
  left: 12px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: #fff;
  width: 15px;
  height: 15px;
  color: #000;
  font-size: 10px;
}

.hoverRight span {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  width: calc(100% - 26px);
  white-space: nowrap;
  -webkit-transition: all 0.5s ease-in-out;
}

.rightstickyItem svg {
  display: block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  fill: #fff;
}
.rightstickyItem.item-1:hover,
.rightstickyItem.item-1.hover {
  margin-left: calc(-80% + 30px);
}
.rightstickyItem.item-2:hover,
.rightstickyItem.item-2.hover {
  margin-left: calc(-90% + 50px);
}

.rightstickyItem.item-3:hover {
  margin-left: calc(-100% + 50px);
}

.rightstickyItem.item-4:hover {
  margin-left: calc(-90% + 30px);
}

.rightstickyItem.item-5:hover {
  margin-left: calc(-82% + 10px);
}
.rightstickyItem:hover span,
.rightstickyItem.hover span {
  opacity: 1;
  color: #fff;
}

.rightstickyWrappersTop a {
  color: #39613e;
}

.rightstickyWrappersTop {
  display: flex;
}

.align-middle {
  align-items: center;
}

.rightstickyItemTop {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 0 0.25rem;
}
.countTop {
  display: flex;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  align-items: center;
  justify-content: center;
  z-index: auto;
  border: 2px solid #f00;
  border-radius: 10px;
  background: #f00;
  padding: 0 6px;
  width: 18px;
  min-width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  white-space: nowrap;
  color: #fff;
  font-size: 10px;
  font-weight: 400;
}

.rightstickyItemTop {
  margin: auto 0.35rem;
}
