@import 'variable.less';

.diamondDetailPopup {
  z-index: 1000000;
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1 !important;
  }
  &.ant-drawer {
    .ant-drawer-content {
      overflow: visible;
    }
  }
  .ant-drawer-header-no-title {
    .ant-drawer-close {
      background: #fff;
      right: auto;
      left: -46px;
      border-radius: 20px;
      top: 8px;
      width: 40px;
      height: 40px;
      line-height: 42px;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .ant-drawer-content-wrapper {
    width: 84% !important;
    padding: 0;
    height: 100%;
    .ant-drawer-body {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
  .diamondDetailWrapper {
    .diamondDetailTopTab {
      position: absolute;
      left: 0;
      top: -45px;
      border-bottom: none;
      z-index: 10000;
    }
  }
  .diamondDetailLeft {
    width: 50%;
    position: relative;
    z-index: 10001;
    .diamondDetailInnerDetail {
      position: relative;
      top: 0;
      .tab-content {
        height: calc(100vh - 120px);
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
  }
  .diamondDetailRight {
    max-height: calc(100vh - 73px);
    overflow: auto;
    background-color: transparent;
  }
  .diamondDetailBottomBox {
    box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.09);
    z-index: 1000;
    top: 0;
    background: #fff;
    width: 100%;
    .diamondDetailBottomAction {
      width: 100%;
    }
  }
}
.diamondDetailWrapper {
  width: 100%;
  // height: 100%;
  .diamondDetailTopTab {
    justify-content: center;
  }
  .diamondDetailInner {
    .diamondDetailMainTab {
      display: flex;
    }
    .basicDetailFull {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      margin-bottom: 0;
      li {
        color: #212431;
        font-size: 20px;
        font-weight: 600;
        list-style: none;
        margin: 0 5px;
      }
    }
  }
}
.diamondDetailLeft {
  width: 50%;
  position: relative;
  // z-index: 10001;
  // height: calc("100vh - 131px");
  // overflow: auto;
  .diamondDetailInnerDetail {
    // height: calc(100vh - 73px);
    // overflow-y: auto;
    // overflow-x: hidden;
    position: sticky;
    top: 0;
    .tab-content,
    .tab-pane {
      height: 100%;
    }
  }
  .tab-pane {
    display: none;
    &.active {
      display: block;
      .slick-slider {
        // width: 100%;
        // height: 100%;
        // color: #000;
        // text-align: center;
        // justify-content: center;
        // align-items: center;
        // min-width: 100%;
        // font-size: 18px;
        // display: grid;
        // font-weight: 600;
        // margin: auto;
        /* height: 100vh; */
        height: 100%;
        .slick-list {
          height: 100%;
          .slick-track {
            height: 100%;
            .slick-slide {
              div {
                height: 100%;
                .no-msg {
                }
              }
            }
            .slick-current {
              height: 100%;
              div {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}
.diamondDetailImageBox {
  height: 100%;
  padding: 20px 10px;
  text-align: center;
  &.htmlViewImage {
    height: calc(100vh - 120px);
  }
  .magnifier {
    // width: 450px !important;
    // height: 450px !important;
    margin: auto;
    overflow: hidden;
  }
  img,
  iframe {
    width: 100%;
    height: 100%;
    margin: auto;
    // object-fit: cover;
    border: none;
    &.certificateImage {
      height: auto;
    }
  }
  img {
    object-fit: contain;
  }
  .imageBlock {
    max-width: 100%;
    height: 100% !important;
    img {
      width: 90%;
      height: 100%;
    }
  }
}
.smallSliderBlock {
  // margin-top: 15px;
  padding: 0 55px;
  .slick-track {
    margin: auto;
  }
  .smallSliderImage {
    width: 60px !important;
    height: 60px;
    display: flex !important;
    margin: auto;
    align-items: center;
    justify-content: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
    img {
      width: 30px;
      height: 30px;
      object-fit: cover;
    }
  }
  &.smallSliderBlockimage {
    .smallSliderImage {
      // width: 80px !important;
      // height: 80px;
      img {
        width: 35px;
        height: 35px;
      }
    }
  }
  .slick-track {
    display: flex;
    justify-content: center;
    .slick-slide {
      width: 80px !important;
      height: 80px;
      margin: 0 0;
      &.slick-cloned {
        display: none;
      }
      &.slick-current {
        .smallSliderImage {
          border: 1px solid #000;
        }
      }
    }
  }
  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
    background: rgba(0, 0, 0, 0.5);
    &:before {
      color: #fff;
    }
    &.slick-prev {
      left: 10px;
    }
    &.slick-next {
      right: 10px;
    }
  }
  .smallimgTitle {
    color: #454545;
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 5px;
    display: block;
    text-align: center;
  }
}
.diamondDetailRight {
  width: 50%;
  // max-height: calc(100vh - 73px);
  // overflow: auto;
  position: relative;
  z-index: 1;
  // background-color: @dashboardCard;
  background-color: #f6f6f6;
  .diamondDetailMore {
    padding: 4px 14px;
    // padding: 14px;
    .detailSmallTitle {
      font-size: 16px;
      // font-size: 18px;
      color: #424242;
      margin-bottom: 0;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 1px;
      margin-bottom: 15px;
    }
    .diamondDetailMoreHalf {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      ul {
        width: 49%;
        padding: 0;
        margin: 0;
        li {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 5px;
          // margin-bottom: 10px;
          span {
            color: #000;
            font-size: 14px;
            font-weight: normal;
            &:first-child {
              width: 115px;
              margin-right: 5px;
              font-size: 12px;
              letter-spacing: 0.5px;
              text-transform: uppercase;
            }
            &:last-child {
              font-size: 10px;
              // font-size: 16px;
              color: #000;
              font-weight: 500;
              word-break: break-word;
              width: calc(~'100% - 120px');
            }
          }
        }
      }

      &.diamondFullBlock {
        ul {
          width: 100%;
          li {
            span {
              &:first-child {
                width: 190px;
              }
              &:last-child {
                width: calc(~'100% - 195px');
              }
              &.addNotesOption {
                display: flex;
                align-items: center;
                textarea {
                  width: calc(~'100% - 110px');
                  border: 1px solid #e2e2e2;
                  background-color: #fff;
                  height: 80px;
                  border-radius: 5px;
                  margin-right: 10px;
                  padding: 10px;
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.detailHalfWhite {
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #fff;
}
.detailHalfGray {
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f6f6f6;
}
.diamondDetailSummary {
  width: 100%;
  .botoomStickyBar {
    display: none !important;
  }
}
.diamondDetailBottomBox {
  position: sticky;
  top: 0;
  padding: 10px;
  background: @dashboardCard;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .diamondDetailBottomAction {
    width: auto;
    height: 53px;
    // border-bottom: 1px solid #e2e2e2;
    border-top: none !important;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: flex-end;
    margin-inline-start: auto;
    .diamondDetailTab {
      border-bottom: none !important;
    }
    .diamondDetailActionItem {
      display: flex;
      align-items: center;
      margin: 5px;
      cursor: pointer;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid @themeColor;
      background-color: @themeColor;
      img {
        width: 20px;
        height: 20px;
        // margin-right: 5px;
      }
      span {
        color: #212431;
        font-weight: 600;
      }
    }
  }
}
.tooltipShow {
  z-index: 100009999;
}
//   popup Detail
.diamondDetailRightPopup {
  padding: 15px;
}
.diamondDetailPage {
  .sideBarPopupButton {
    position: fixed;
    bottom: 0;
    width: 50%;
    right: 0;
    left: auto;
    box-shadow: none;
    background-color: transparent;
  }
}
.nodataFoundDetail {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .nodataFoundInner {
  }
  img {
    width: 80px;
    height: 80px;
  }
  span {
    width: 100%;
    color: #000;
    margin-top: 10px;
    display: block;
    font-size: 12px;
  }
}
.diamondDetailSperateBlock {
  // margin: 0 10px;
  // border: 1px solid #ddd;
  // padding:8px 10px;
  display: flex;
  align-items: center;
  // border-radius: 5px;
  // cursor: pointer;
  span {
    color: @themeColor;
    font-weight: 600;
  }
  img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
    &.hoverImg {
      display: none;
    }
    &.defualtImg {
      display: block;
    }
  }
  &:hover {
    img {
      &.hoverImg {
        display: block;
      }
      &.defualtImg {
        display: none;
      }
    }
    span {
      color: #fff;
    }
  }
}
