@import '../../../styles/variable.less';

.UserProfileDefault {
  width: 30px;
  height: 30px;
  // background-color: @themeColor;
  background-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 10px;
  img {
    width: 16px;
    height: 16px;
  }
  &.lg-size {
    width: 100px;
    height: 100px;
    img {
      width: 36px;
      height: 36px;
    }
  }
  &.md-size {
    width: 80px;
    height: 80px;
    text-align: center;
    align-items: center;
    img {
      width: 26px;
      height: 26px;
    }
  }
}

@primary-color: #000;