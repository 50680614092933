.ErrorBoundary {
  display: flex;
  flex-flow: column;
  padding: 0.5rem;
  height: 100%;

  &Alert {
    margin-top: auto;
    margin-bottom: auto;
  }

  &Header,
  &ButtonWrapper {
    text-align: center;
  }

  &Error {
    padding: 0.5rem;
    width: 100%;
    overflow: auto;
    color: red;
  }
}

@primary-color: #000;