@import '../styles/variable.less';

.text-left {
  text-align: left !important;
}
.loginMainWrapper {
  display: flex;
  // padding: 20px;
  min-height: 100vh;
  background: #fafafa;
  &.registerLogWrapper {
    padding-right: 0;
  }
  .loginLeftWrapper {
    display: grid;
    height: 100%;
  }
  .loginLeftBlock {
    // width: 50%;
    width: 100%;
    height: 100vh;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      &:focus {
        outline: none;
      }
    }
    .loginLeftBlockInner {
      border-radius: 10px;
      // background-color: rgba(192,158,124 ,0.20);
      padding: 50px 20px;
      position: relative;
      background-image: url('../assets/img/login/bg.jpg');
      background-position: top;
      position: sticky;
      top: 20px;
      height: calc(100vh - 40px);
    }
    .loginLeftOverlay {
      position: absolute;
      background: rgba(65, 65, 67, 0.35);
      width: 100%;
      height: 100%;
      top: 0;
      border-radius: 10px;
      left: 0;
    }
    .loginLeftContent {
      position: relative;
      max-width: 70%;
      margin: auto;
      h2 {
        font-size: 50px;
        color: rgb(61, 56, 56);
        line-height: 70px;
        font-weight: 600;
      }
      button {
        padding: 15px 25px;
        font-size: 16px;
      }
    }
    .loginBasicList {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        li {
          list-style: none;
          a {
            color: #000;
            margin: 0 10px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .LoginRightBlock {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100vh;
    overflow: auto;
    padding: 10px 10px 150px 10px;
    .loginDetail {
      padding: 30px;
      z-index: 1;
      width: 550px;
      position: relative;
      box-shadow: 0 0 17px 0px rgba(0, 0, 0, 0.11);
      background: #fff;
      /* position: absolute; */
      border-radius: 20px;
      top: 0;
      height: auto;
      margin: auto;
      // &:after{
      //   top: 0;
      //   content: "";
      //   width: 100%;
      //   height: 75%;
      //   box-shadow: 0 0 17px 0px rgba(0, 0, 0, 0.11);
      //   background: #fff;
      //   position: absolute;
      //   border-radius: 20px;
      //   left: 0;
      // }
      &.registerForm {
        width: 860px;
        padding: 0 0;
        /* overflow: auto; */
        top: 0;
        .loginButton {
          bottom: -140px;
        }
        .registerMainScroll {
          // height: calc(100vh - 160px);
          // overflow: auto;
          padding: 10px 0 35px 0;
        }
        .registrationSelect {
          flex-wrap: wrap;
          .registrationSelectItem {
            border: 1px solid #e1e1e1;
            background-color: #fafafa;
            padding: 8px 12px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            margin-right: 12px;
            margin-bottom: 5px;
            min-width: 160px;
            cursor: pointer;
            .registrationSelectImg {
              width: 40px;
              height: 40px;
              border: 1px solid #ccc;
              display: flex;
              margin-right: 10px;
              border-radius: 5px;
              background: #fff;
              img {
                margin: auto;
                width: 25px;
                height: 25px;
              }
            }
            span {
              font-weight: 600;
            }
            &.active {
              background-color: @themeLightColor;
              border: 1px solid @themeColor;
              .registrationSelectImg {
                border: 1px solid @themeColor;
              }
            }
          }
        }
        .loginHead {
          // padding: 0 25px 0 25px;
          // width: 80%;
        }
        .accountType {
          padding: 0 0 25px 25px;
        }
        .registerMainBlock {
          form {
            padding: 0 25px 0 25px;
            width: 100%;
            .registerFiledBlock {
              display: flex;
              margin-bottom: 30px;
              flex-wrap: wrap;
              .signuppassword {
                position: absolute;
                width: 20px;
                height: 20px;
                top: 348px;
                left: 350px;
                cursor: pointer;
              }
              .signupconfirm {
                position: absolute;
                width: 20px;
                height: 20px;
                top: 348px;
                right: 80px;
                cursor: pointer;
              }
              .from-group {
                width: calc(50% - 20px);
                margin-right: 20px;
              }
              .profileUploadBlock {
                margin-bottom: 15px;
                .fileUploadBlock {
                  margin-left: 0;
                  max-width: 100%;
                }
              }
              .fileUploadedBlockItem {
                -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);
                box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.09);
                border-radius: 10px;
                padding: 10px;
                position: relative;
                margin: 5px;
                width: 120px;
                height: 120px;
                border: none;
                img {
                  max-width: 100%;
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }
                .deleteIcon {
                  position: absolute;
                  top: -8px;
                  right: -6px;
                  width: 20px;
                  height: 20px;
                  border-radius: 100%;
                  border: 1px solid #ddd;
                  display: flex;
                  cursor: pointer;
                  img {
                    width: 8px;
                    height: 8px;
                    margin: auto;
                  }
                }
              }
            }
          }
        }
      }
      .loginHead {
        margin-bottom: 20px;
        // margin-bottom: 50px;
        text-align: center;
        position: relative;
        z-index: 1;
        h3 {
          font-size: 24px;
          line-height: 30px;
          font-weight: 600;
          margin-bottom: 15px;
        }
        p {
          max-width: 70%;
          font-size: 14px;
          color: #555;
          margin: auto;
        }
      }
      .loginBox {
        position: relative;
        z-index: 1;
        .from-group {
          margin-bottom: 25px;
        }
      }
      .loginLinkBlock {
        margin: 15px 0 50px 0;
      }
      .registerLogLink {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        color: #fff;
        a {
          color: #fff;
          font-weight: 600;
          margin-left: 5px;
          font-size: 16px;
          text-decoration: underline;
          &:hover {
            color: #fff;
          }
        }
      }
      .rememberCheckBox {
        display: flex;
        align-items: center;
        .rememberLabel {
          margin-left: 10px;
          color: #414143;
        }
      }
    }
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      position: fixed;
      left: 0;
      right: 0;
      margin: auto;
      background: rgba(0, 0, 0, 0.33);
      top: 0;
    }
  }
  .loginButton {
    // display: flex;
    // justify-content: space-between;
    text-align: center;
    position: absolute;
    width: 100%;
    left: 0;
    .commonButton {
      &.login-button {
        width: 30%;
        padding: 10px 10px;
      }
    }
    .commonButton,
    .commonOutline {
      padding: 16px 45px;
      // padding: 16px 45px;
      width: 49%;
      font-size: 16px;
    }
  }
}

.registerTitie {
  font-size: 18px;
  font-weight: 500;
}
ul.tabList {
  margin: 0;
  padding: 0;
  // position: absolute;
  li {
    list-style: none;
    a {
      line-height: 24px;
      font-weight: 600;
      margin-bottom: 20px;
      display: flex;
      color: #000;
      cursor: pointer;
      span {
        width: 25px;
        height: 25px;
        border-radius: 100%;
        border: 1px solid #000;
        margin-right: 8px;
        display: block;
        text-align: center;
      }
      &.is-active {
        text-decoration: underline;
        font-weight: 600;
        span {
          background-color: #000;
          color: #fff;
          border: 1px solid #000;
          font-weight: normal;
        }
      }
    }
  }
}
p {
  color: #555;
  line-height: 24px;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 1200px) {
  .loginMainWrapper {
    &.registerLogWrapper {
      background: #333;
      .loginLeftBlock {
        display: none;
      }
    }
    .LoginRightBlock {
      padding-bottom: 10px;
      .loginDetail {
        width: 500px;
        .loginHead {
          p {
            max-width: 100%;
          }
        }
      }
    }

    .loginButton {
      width: 100%;
      left: 0;
      .commonOutline,
      .commonButton {
        width: auto;
      }
    }
  }
}
@media screen and (max-width: 991px) {
  .loginMainWrapper {
    .LoginRightBlock {
      .loginDetail {
        width: 400px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .loginMainWrapper {
    .LoginRightBlock {
      padding-left: 70px;
      padding-right: 70px;
      .loginDetail {
        &.registerForm {
          .registerMainScroll {
            padding-bottom: 55px;
          }
          .registerMainBlock {
            form {
              padding: 0 15px;
              .registerFiledBlock {
                .from-group {
                  margin-right: 0;
                  width: calc(50% - 10px);
                  &:nth-child(odd) {
                    margin-right: 20px;
                  }
                }
              }
              .customCheckBox {
                flex-wrap: nowrap;
                label {
                  width: calc(100% - 16px);
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .loginMainWrapper {
    .loginButton {
      .commonButton {
        // padding: 10px 10px;
        padding: 16px 35px;
        // padding: 16px 45px;
        // width: 49%;
        font-size: 16px;

        &.login-button {
          padding: 10px 10px;
        }
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .loginMainWrapper {
    background: #333;
    .loginLeftBlock {
      display: none;
    }

    .LoginRightBlock {
      .loginDetail {
        padding: 30px 15px;
        &.registerForm {
          .registerMainScroll {
            padding-bottom: 55px;
          }
          .registerMainBlock {
            form {
              padding: 0 15px;
              .registerFiledBlock {
                .from-group {
                  width: 100%;
                  margin-right: 0;
                }
              }
              .customCheckBox {
                flex-wrap: nowrap;
                label {
                  width: calc(100% - 16px);
                }
              }
            }
          }
        }
      }
    }

    .loginButton {
      width: 100%;
      left: 0;
      .commonOutline,
      .commonButton {
        width: auto;
      }
    }
  }
}

@primary-color: #000;