@import 'variable.less';

@media (max-width: 767px) {
  .bidEndBlock {
    margin: 0 10px;
    background-color: #f9f9f9;
    padding: 5px 10px;
    border-radius: 0 0 10px 10px;
    .bidEndLabel {
      text-align: center;
      width: 100%;
      margin-bottom: 5px;
      display: block;
    }
    .countdownItem {
      width: 25%;
    }
  }
  .botoomStickyBar {
    justify-content: center;
    margin-left: 00px;
    width: calc(100% - 0px);
  }
  .searchTopInputValue {
    padding: 0 10px;
    width: 100%;
    .searchTopBlock {
      width: 100%;
    }
  }
  .demandPopupTwoValue {
    flex-wrap: wrap;
    .inputBlock {
      width: 100%;
    }
  }
  .searchResultListWrapper {
    margin-top: 10px;
    background-color: #f9fff3;
    // height: 98%;
  }
  .diamondListingAction {
    display: none;
  }
  .listingTopBlock {
    // display: block !important;
    position: relative;
    .gridListIcon {
      // position: absolute;
      // top: 3px;
      // right: 0;
    }
    .searchInnerFilterMain {
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    .filterOption {
      position: relative;
      img {
        width: 14px;
        height: 14px;
      }
      .filterStatus {
        background-color: @themeColor;
        color: #fff;
        width: 14px;
        height: 14px;
        border-radius: 100%;
        position: absolute;
        right: -7px;
        top: -5px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .mobileRightAction {
      padding-right: 10px;
    }
    .mobileStoneSelect {
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .mobileSelectStoneItem {
        text-align: center;
        width: auto;
        span {
          font-size: 12px;
          display: block;
          &:first-child {
            font-weight: 600;
            color: #000;
          }
          &:last-child {
            font-size: 11px;
            font-weight: 500;
          }
        }
      }
    }
  }
  .diamondListMobile {
    overflow: auto;
    // padding: 10px;
    // padding-bottom: 65px;
    .diamodListItemWrapper {
      border: 1px solid #e2e2e2;
      border-radius: 10px;
      margin-bottom: 10px;
      position: relative;
      &:after:not(.noStatus) {
        content: '';
        width: 5px;
        height: 40px;
        background: transparent;
        position: absolute;
        border-radius: 0 10px 10px 0;
        margin: auto;
        left: 0;
        top: 0;
        bottom: 0;
        background: #6bc950;
      }
      &.green {
        &:after {
          background: #6bc950;
        }
      }
      &.red {
        &:after {
          background: #db1c1c;
        }
      }
      &.blue {
        &:after {
          background: #307bea;
        }
      }
      .mobileListHeade {
        background-color: #fafafa;
        padding: 10px;
        border-radius: 10px 10px 0 0;
        border-bottom: 1px solid #e2e2e2;
        .mobileListHeadeBlock {
          display: flex;
          justify-content: space-between;
          .mobileListItem {
            text-align: center;
            .countDownBlock {
              .countdownItem {
                margin: 0 2px;
                span {
                  font-size: 12px;
                  text-transform: capitalize;
                }
              }
            }
            span,
            a {
              display: block;
              // &:first-child{
              //     text-transform: uppercase;
              //     font-size: 12px;
              // }
              &:last-child {
                color: #000;
                font-weight: 600;
                font-size: 12px;
              }
              &.stageStatus {
                width: 10px;
                height: 10px;
                border-radius: 100%;
                // background-color: #000;
                margin: 5px auto auto auto;
              }
            }
          }
        }
      }
      .quoteExtraDetail {
        padding: 5px 5px 5px 5px;
        border-top: 1px solid #ddd;
        .quoteExtraItem {
          span {
            font-size: 12px;
            color: #000;
            &:first-child {
              font-weight: 600;
            }
          }
          .tableInput {
            width: 100px;
          }
        }
      }
      .mobileDiamondListBottom {
        .mobileListHeadeBlock {
          display: flex;
          flex-wrap: wrap;
          padding: 5px 5px 5px 10px;
          border-radius: 0 0 10px 10px;
          justify-content: space-between;
          // background-color: #ebebeb;
          &.invoice-history {
            .mobileListItem {
              width: 33.33%;
              &:nth-child(2),
              &:nth-child(4) {
                text-align: left;
              }
              span {
                color: #555;
                font-weight: 500;
                // font-size: 11px;
                font-size: calc(12px + (16 - 14) * ((100vw - 300px) / (767 - 300)));
                letter-spacing: 0;
              }
              &.totalPrice {
                &.amt-value {
                  text-align: right;
                }
              }
            }
          }
          .mobileListItem {
            width: 50%;
            &:nth-child(2),
            &:nth-child(4) {
              text-align: right;
            }
            span {
              color: #555;
              font-weight: 500;
              // font-size: 11px;
              font-size: calc(12px + (16 - 14) * ((100vw - 300px) / (767 - 300)));
              letter-spacing: 0;
            }
            &.basicDetail {
              span {
                font-weight: 600;
              }
            }
            &.totalPrice {
              span {
                font-weight: 600;
                color: #000;
              }
            }
          }
        }
      }
      .mobileListBlock {
        padding: 10px;
        display: -webkit-box;
        /* flex-wrap: wrap; */
        overflow: auto;
        .mobileListItem {
          min-width: 30%;
          display: flex;
          justify-content: space-between;
          margin: 2px 10px;
          align-items: center;
          position: relative;
          span {
            // &:first-child{
            //     font-weight: 500;
            //     font-size: 12px;
            //     text-transform: uppercase;
            //     color: #555;
            //     margin-right: 10px;
            // }
            // &:last-child{
            font-weight: 600;
            color: #000;
            // }
          }
          &:after {
            content: '';
            width: 1px;
            height: 15px;
            background: #9b9b9b;
            position: absolute;
            right: -10px;
          }
        }
        &::-webkit-scrollbar {
          display: none;
        }
      }
      &.active {
        border: 1px solid @themeColor;
        box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.11);
        .mobileListHeade {
          background-color: #fff;
        }
        .mobileDiamondListBottom {
          .mobileListHeadeBlock {
            background-color: #fff;
          }
        }
      }
    }
    .quotePageWrapper {
      .mobileListItem {
        span {
          font-size: 12px !important;
        }
      }
      .mobileDiamondListBottom {
        span {
          font-size: 11px !important;
        }
      }
    }
  }

  // Offer
  .offerWrapper {
    .offerTopBlock {
      display: block !important;
      position: sticky;
      top: -20px;
      background: #fff;
      padding: 10px 0;
      z-index: 1;
      margin-top: 0;
    }
  }

  .mobileFilterSideBar {
    z-index: 100000;
    &.result-drawer {
      .ant-drawer-content-wrapper {
        width: 50% !important;
      }
    }
    .ant-drawer-close {
      display: none;
    }
    .ant-drawer-body {
      padding: 15px;
    }
    .mobileFilterPopup {
      .mobileFilterDetail {
        margin-bottom: 25px;
        .mobileFilterListWrapper {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .mobileFilterItem {
            background-color: @themeLightColor;
            padding: 2px 8px !important;
            border-radius: 20px;
            margin: 3px;
            display: flex;
            align-items: center;
            span {
              color: @themeColor;
              font-size: 12px;
              font-weight: 500;
            }
            .filterClose {
              width: 14px;
              height: 14px;
              border-radius: 100%;
              background-color: @themeColor;
              display: flex;
              margin-left: 5px;
              img {
                width: 6px;
                height: 6px;
                margin: auto;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

.mobileGroupingCard {
  margin-bottom: 10px;
  .mobileGroupingHead {
    background-color: @themeColor;
    font-size: 10px;
    color: #fff;
    padding: 2px 5px;
    border-radius: 5px 5px 0 0;
  }
  .mobileGroupingList {
    .diamodListItemWrapper {
      margin-bottom: 0;
      border-radius: 0;
      &:first-child {
        // border-bottom: none;
        border-radius: 0;
      }
      &:last-child {
        border-radius: 0 0 10px 10px;
      }
    }
  }
}
.mobileSortingDropdown {
  min-width: 150px;
  .mobileSortingDropdownList {
    padding: 0;
    background-color: #fff;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    z-index: 10;
    position: relative;
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style: none;
        font-size: 14px;
        font-weight: 500;
        color: #000;
        border-bottom: 1px solid #e2e2e2;
        padding: 10px;
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
.mobileOfferScroll {
  // max-height: calc(100vh - 530px);
  padding: 10px 0;
}

// share
// .setLoader {
.m-p-r {
  .sideBarPopupButton {
    position: unset;
    background-color: transparent;
    // box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 9%);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.09);
  }
}
// }

.m-chanage {
  // justify-content: space-evenly;
  li {
    list-style-type: none;
    margin-left: 0;
    background: #fafafa;
    border: 1px solid #e1e1e1;
    background-color: #fafafa;
    border-radius: 5px;
    .dropdownBottomItem {
      margin: 10px 20px;
    }
  }
}
.shareStoneMobileItem {
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 10px;
  &.border-bottom-none {
    border-bottom: none;
    .m-chanage {
      display: flex;
      // justify-content: space-between;
      justify-content: space-evenly;

      flex-wrap: wrap;
      margin: 10px 0px;
      padding: 10px 0px;
      li {
        list-style-type: none;

        // list-style: none;
        // border: 1px solid #e1e1e1;
        // background-color: #fafafa;
        // padding: 8px 10px;
        // min-width: 100px;
        // cursor: pointer;
        // border-radius: 5px;
        // font-size: 14px;

        margin-left: 0;
        background: #fafafa;
        border: 1px solid #e1e1e1;
        background-color: #fafafa;
        border-radius: 5px;

        /* margin: 4px 10px 4px 4px; */

        .dropdownBottomItem {
          display: flex;
          span {
            &.shareOptionLabel {
              font-weight: 500;
              color: #000;
            }
          }
        }
      }
    }
  }
  .shareStoneMobileHead {
    display: flex;
    background-color: #fafafa;
    padding: 10px;
    // justify-content: space-between;
    .shareDropdownMobileHead {
      .dropdownIconCheckBox {
        margin-right: 10px;
      }
    }
    span {
      color: #000;
      font-weight: 600;
    }
    img {
      width: 14px;
      height: 14px;
    }
  }
}
.mobileShareOption {
  padding: 10px 20px;
  li {
    list-style: none;
    .dropdownBottomItem {
      display: flex;
      margin-bottom: 10px;
      .shareOptionLabel {
        color: #000;
        font-weight: 500;
      }
    }
  }
}
