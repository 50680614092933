@import "../../styles/variable.less";

.page_not{
  width: 100%;
  min-height: 100vh;
  display: block;
  .page_wrap{
    display: flex;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    justify-content: center;
    figure,.page_not_content{
      width: 50%;
      
    }
    figure{
      padding: 100px;
      text-align: center;
      img{
        max-width: 100%;
        max-height: 400px;
      }
    }
    .page_not_content{
      h1{
        font-size: 150px;
        margin-bottom: 50px;
        font-weight: 500;
      }
      h3{
        font-size: 25px;
        font-weight: 500;
      }
      p{
        font-size: 14px;
        margin-bottom: 20px !important;
      }
    }
  }
}
@media screen and (max-width : 767px)
{
  .page_not{
    .page_wrap{
      flex-wrap: wrap;
      figure,.page_not_content{
        width: 100%;
      }
      figure{
        padding-top: 0;
        padding-bottom: 0;
      }
      .page_not_content{
        text-align: center;
        padding: 0 20px;
        h1{
          font-size: 100px;
          margin-bottom: 30px;
        }
        h3{
          font-size: 25px;
          font-weight: 500;
        }
        p{
          font-size: 14px;
          margin-bottom: 20px !important;
        }
      }
    }
  }
}
@primary-color: #000;