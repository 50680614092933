@import 'variable.less';
@media (max-width: 768px) {
  .diamondDetailRight {
    .diamondDetailMore {
      .diamondDetailMoreHalf {
        ul {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .diamondDetailInnerDetail {
    .customerMaintab {
      display: -webkit-box;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .diamondDetailPopup {
    .diamondDetailLeft {
      width: 100%;
      .diamondDetailInnerDetail {
        .tab-content {
          height: auto;
        }
      }
    }
  }
  .mobilediamondDetail {
    padding-bottom: 50px;
  }
  .mobilediamond {
    width: 100%;
    .diamondDetailInnerDetail {
      height: auto;
      .diamondDetailImageBox {
        .magnifier {
          width: 100% !important;
        }
        &.htmlViewImage {
          height: auto;
          iframe {
            height: 400px;
          }
        }
        iframe {
          height: 400px;
        }
      }
    }
  }
  .mobileRightDetail {
    flex-wrap: wrap;
    width: 100%;
    display: -webkit-box;
    align-items: flex-start;
    overflow: auto;
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content;
    padding: 10px;
    .diamondDetailMore {
      width: 100%;
      height: max-content;
      border: 1px solid #e2e2e2;
      border-radius: 10px;
      margin-bottom: 10px;
      padding: 15px;
      max-height: 535px;
      overflow: auto;
      .diamondDetailMoreHalf {
        ul {
          width: 100%;
          li {
            span {
              &:last-child {
                text-align: right;
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
