@import '../../../styles/variable.less';

.registerFiledBlock {
  .form-group {
    &.inputBlock-uniq {
      width: calc(50% - 20px);
      margin-right: 20px;
    }
  }
  .loginBox {
    .inputBlock {
      &.inputBlock-uniq {
        width: calc(50% - 20px);
        margin-right: 20px;
      }
    }
  }
  .mobileCodeBlock {
    position: relative;
    &.border-bottom {
      // border: none;
      border-bottom: 1px solid #e4e4e4;
      // width: 100%;
      // height: auto;
      // padding: 7px 0;
    }
    &.border-bottom-red {
      border-bottom: 1px solid #e4e4e4;
      .intl-tel-input {
        border-bottom: 0px solid #e4e4e4;
        width: 100%;
        height: auto;
        input {
          border: none;
          width: 100%;
          // border-bottom: 1px solid #e4e4e4;

          // padding: 10px 0px;
          // padding-right: 6px;
          // padding-left: 52px;
          padding: 6px 10px 10px 52px;
          .form-control {
            width: 100%;
          }
        }
        &.allow-dropdown {
          &.separate-dial-code {
            .selected-flag {
              background-color: transparent;
              display: flex;
            }
            .selected-dial-code {
              padding-left: 10px;
            }
          }
        }
      }
    }
    &.border-bottom {
      .intl-tel-input {
        border: none !important;
        border-bottom: 1px solid #e4e4e4;
        width: 100%;
        height: auto;
        // padding: 7px 0;
        input {
          border: none;
          width: 100%;
          padding: 10px 0px;
          .form-control {
            width: 100%;
          }
        }
        &.allow-dropdown {
          &.separate-dial-code {
            // &.iti-sdc-3 {
            //   input[type='tel'],
            //   input {
            //     width: 100%;
            //   }
            // }
            .selected-flag {
              background-color: transparent;
              display: flex;
            }
            .selected-dial-code {
              padding-left: 10px;
            }
          }
        }
      }
    }
    .intl-tel-input {
      border: none;
      border-bottom: 1px solid #e4e4e4;
      width: 100%;
      height: auto;
      // padding: 7px 0;
      input {
        border: none;
        // width: 100%;
      }
      &.allow-dropdown {
        &.separate-dial-code {
          .selected-flag {
            background-color: transparent;
            display: flex;
          }
          .selected-dial-code {
            padding-left: 10px;
          }
        }
      }
    }
  }
}

.countryCallingCodeDropdown .mobileCodeBlock {
  position: relative;
  &.border-bottom {
    // border: none;
    // border-bottom: 1px solid #e4e4e4;
    // width: 100%;
    // height: auto;
    // padding: 7px 0;
  }
  &.border-bottom {
    .intl-tel-input {
      border: none;
      border-bottom: 1px solid #e4e4e4;
      width: 100%;
      height: auto;
      // padding: 7px 0;
      .hide {
        display: none;
      }
      .country-list {
        position: absolute;
        z-index: 2;
        list-style: none;
        text-align: left;
        padding: 0;
        margin: 0 0 0 -1px;
        box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
        background-color: white;
        border: 1px solid #ccc;
        white-space: nowrap;
        max-height: 200px;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
      }
      .flag-container {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 1px;
      }
      &.allow-dropdown {
        .flag-container {
          right: auto;
          left: 0;
          width: 100%;
        }
      }
      input {
        border: none;
        width: 100%;
        padding: 10px 0px;
        .form-control {
          width: 100%;
        }
      }
      &.allow-dropdown {
        &.separate-dial-code {
          // &.iti-sdc-3 {
          //   input[type='tel'],
          //   input {
          //     width: 100%;
          //   }
          // }
          .selected-flag {
            background-color: transparent;
            display: flex;
          }
          .selected-dial-code {
            padding-left: 10px;
          }
        }
      }
    }
  }
  .intl-tel-input {
    border: none;
    border-bottom: 1px solid #e4e4e4;
    width: 100%;
    height: auto;
    // padding: 7px 0;
    input {
      border: none;
      // width: 100%;
    }
    &.allow-dropdown {
      &.separate-dial-code {
        .selected-flag {
          background-color: transparent;
          display: flex;
        }
        .selected-dial-code {
          padding-left: 10px;
        }
      }
    }
  }
}

.form-group:extend(.from-group),
.inputBlock {
  margin-bottom: 15px;
  position: relative;
  label {
    color: #000;
    font-size: 14px !important;
    display: block;
    width: 100%;
    font-weight: 600;
  }
  .input-wrapper,
  .inputWrapper {
    position: relative;

    .intl-tel-input {
      width: 100%;

      &.separate-dial-code {
        .selected-flag {
          display: flex !important;
          flex-direction: row;
          background: none !important;
          background-color: none !important;

          .iti-flag {
            margin-right: 0.75rem;
          }

          .selected-dial-code {
            padding-left: 0 !important;
          }
        }
      }
    }

    input {
      border: none;
      color: #555;
      font-weight: 400;
      font-size: 14px;
      padding: 10px 0;
      width: 100%;
      border-bottom: 1px solid #e4e4e4;
      background-color: transparent;
      line-height: 16px;
      &::placeholder {
        color: #555;
        font-weight: 400;
        font-size: 14px;
      }
      &:focus {
        border-bottom: 1px solid @themeColor;
        outline: none;
      }
    }
    .show-hide-password,
    .signinpassword {
      position: absolute;
      width: 20px;
      height: 20px;
      right: 0.25rem;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.plusInputRemove {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 30px;
  margin: auto;
  display: flex;
  .closeInoutItem,
  .plusInputItem {
    width: 25px;
    height: 25px;
    background-color: @themeColor;
    border-radius: 100%;
    display: flex;
    cursor: pointer;
    &.closeInoutItem {
      margin-left: 5px;
    }
  }
  img {
    width: 10px;
    margin: auto;
    height: 10px;
    &.closeInput {
      width: 8px;
      height: 8px;
    }
  }
}
.has-errors,
.error-message {
  input {
    border-bottom: 1px solid #ee3b3b !important;
  }
}
.error-msg {
  margin-top: 150px;
}

@media screen and (max-width: 767px) {
  .form-group {
    &.inputBlock-uniq {
      width: 100%;
    }
  }

  // .inputBlock {
  //   &.inputBlock-uniq {
  //     width: 100%;
  //   }
  // }
}

@primary-color: #000;