@import 'variable.less';

.savedSearchMainWrapper {
  padding: 10px 0;
  max-width: 1240px;
  margin: auto;
  .savedSearchWrapper {
    padding: 10px;
    height: calc(~'100vh - 292px');
    position: relative;
    // margin-top: 20px;
    .savedSearchItem {
      width: 100%;
      border-radius: 10px;
      background-color: #fff !important;
      box-shadow: 0 0 9px 0px rgba(0, 0, 0, 9%);
      border: 1px solid #efefef;
      position: relative;
      margin-bottom: 15px;
      // &:after{
      //     content: "";
      //     width: 7px;
      //     height: 46px;
      //     background: #6e8fe7;
      //     position: absolute;
      //     border-radius: 0 10px 10px 0;
      //     margin: auto;
      //     left: 0;
      //     top: 0;
      //     bottom: 0;
      // }
      .savedSearchTop {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e8e8e8 !important;
        padding: 10px 20px;
        .savedTitle {
          font-size: 15px;
          // font-size: 16px;
          color: #000;
          font-weight: 600;
          .noOfDiamondTitle {
            font-size: 14px;
            font-weight: 400;
            margin-left: 5px;
          }
        }
        .customerName {
          display: flex;
          align-items: center;
          color: #7f7f7f;
          font-size: 13px;
          // font-size: 14px;
          span {
            margin-right: 5px;
          }
        }
      }
      .savedSearchAction {
        display: flex;
        .savedSearchActionItem {
          margin-left: 10px;
          cursor: pointer;
          img {
            width: 16px;
            height: 16px;
          }
        }
      }
      .savedSearchBottom {
        display: flex;
        flex-wrap: wrap;
        padding: 5px 20px;
        .savedSearchValue {
          display: flex;
          margin: 5px 20px 5px 0;
          position: relative;
          flex-wrap: wrap;
          span {
            font-size: 13px;
            // font-size: 14px;
            &:first-child {
              margin-right: 5px;
              font-weight: bold;
              color: #7f7f7f;
            }
          }
          &:after {
            content: '';
            background: #c9c9c9;
            width: 1px;
            height: 13px;
            position: absolute;
            right: -10px;
            display: block;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          &:last-child {
            &:after {
              content: none;
            }
          }
        }
      }
    }
  }
}
.addOption {
  width: 40px;
  height: 40px;
  position: absolute;
  /* box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.32); */
  border-radius: 100%;
  display: flex;
  border: 1px solid #000000;
  right: -8px;
  top: -54px;
  cursor: pointer;
  z-index: 1;
  background: #fff;
  img {
    margin: auto;
    width: 16px;
    height: 16px;
  }
}
@media screen and (max-width: 767px) {
  .savedSearchMainWrapper {
    .savedSearchWrapper {
      .savedSearchItem {
        .savedSearchTop {
          .savedTitle {
            .noOfDiamondTitle {
              display: table-footer-group;
              // display: block;
              display: -webkit-inline-box !important;
              margin-left: 0;
              span {
                &.i-flex {
                  display: block;
                  // display: unset;
                  // display: inline-flex;
                }
              }
            }
          }
        }
        .savedSearchAction {
          .savedSearchActionItem {
            .activeStatus {
              white-space: nowrap;
            }
          }
        }
        .savedSearchTop {
          .savedTitle {
            .noOfDiamondTitle {
              display: block;
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}
