@import "../../../styles/variable.less";

.datePicker {
  margin-bottom: 15px;
  .ant-calendar-picker {
    width:100%;
    input {
      border: none;
      color: #000;
      font-weight: 400;
      font-size: 14px;
      padding: 8px 0;
      width: 100%;
      border-bottom:1px solid #d2d3d4;
      line-height: 16px;
      border-radius: 0 !important;
      height: 35px;
      &::placeholder{
        color: #000;
      }
      &:hover,
      &:focus{
        box-shadow: none !important;
      }
    }
    &:hover {
      .ant-calendar-picker-input:not(.ant-input-disabled) {
        border-bottom: 1px solid @themeColor;
        box-shadow: none !important;
      }
    }
  }
}
.datePickerZindex {
  z-index: 1000001 !important;
  .ant-calendar-date {
    &:hover {
      background-color: @themeLightColor;
    }
  }
  .ant-calendar-today .ant-calendar-date {
    background-color: @themeLightColor;
    border: 1px solid @themeColor;
    color: @themeColor;
  }
}

@primary-color: #000;